import Collection from 'core/model/Collection';
import DeniedTrafficModel from './DeniedTrafficModel';

class DeniedTrafficCollection extends Collection {
  get model() {
    return DeniedTrafficModel;
  }
}

export default DeniedTrafficCollection;
