import { computed } from 'mobx';
import Collection from 'core/model/Collection';
import $syn from 'app/stores/synthetics/$syn';
import AgentModel from 'app/stores/synthetics/AgentModel';
import getAgentBreakdown from 'app/views/cloudPerformance/utils/getAgentBreakdown';
import getServiceTest from 'app/views/cloudPerformance/utils/getServiceTest';
import { getSparklineData, getSparklineDataRollup } from 'app/views/cloudPerformance/utils/getSparklineData';
import { getDimensionFieldName } from 'app/views/hybrid/maps/cloudDimensionConstants';
import { getWorstHealth } from 'app/views/synthetics/utils/syntheticsUtils';
import PerfMonitorServiceModel from './PerfMonitorServiceModel';

class PerfMonitorServiceCollection extends Collection {
  /*
    cache containing all the unique services we can use as filters in DE queries
    filterable services will be keyed by their 'well-known' service name:

    {
      S3: ['S3.us-east-1', 'S3.us-east-2', ...],
      ...
    }
  */
  filterableServices = {};

  /*
    Represents all the network interface/region/service/virtual network data related to private service connections

    networkInterfaces: {
      <NETWORK_INTERFACE_ID>: {
        endpoint, // full network interface object
        region,
        service,
        virtualNetworkId
      },
      ...
    },

    virtualNetworks: {
      <VIRTUAL_NETWORK_ID>: {
        agent: <AGENT_DETAILS>,
        name
      },
      ...
    }
  */
  networkInterfaceMap = {
    aws: {
      networkInterfaces: {},
      virtualNetworks: {}
    },

    azure: {
      networkInterfaces: {},
      virtualNetworks: {}
    }
  };

  get model() {
    return PerfMonitorServiceModel;
  }

  get filterFieldWhitelist() {
    return new Set(['virtualNetworkId', 'region', 'connectionType']);
  }

  // returns a count of unique services
  @computed
  get serviceCount() {
    return this.unfiltered.reduce((acc, model) => {
      const service = model.get('service');

      if (!acc.includes(service)) {
        return acc.concat(service);
      }

      return acc;
    }, []).length;
  }

  // provides options for the SelectServices selector dialog
  @computed
  get serviceSelections() {
    const { rollup } = this;

    return Object.keys(rollup).map((serviceName) => {
      const item = rollup[serviceName];
      const traffic = item.fromTraffic + item.toTraffic;

      return { value: serviceName, traffic };
    });
  }

  // rolls up traffic stats, connection type counts, sparkline data, and models for all services
  @computed
  get rollup() {
    const rollup = this.models.reduce((acc, model) => {
      const cloudProvider = model.get('cloudProvider');
      const service = model.get('service');
      const fromTraffic = model.get('fromTraffic');
      const toTraffic = model.get('toTraffic');
      const serviceRollup = acc[service] || {
        fromTraffic: 0,
        toTraffic: 0,
        data: [],
        connectionType: { public: 0, private: 0 },
        cloudProvider
      };

      // roll up service traffic stats
      serviceRollup.fromTraffic += fromTraffic;
      serviceRollup.toTraffic += toTraffic;

      // add to the public/private connection type counts
      serviceRollup.connectionType[model.get('connectionType')] += 1;

      // associate the model with its service
      serviceRollup.data.push(model);

      return {
        ...acc,
        [service]: serviceRollup
      };
    }, {});

    // finish up by aggregating the sparkline data by service
    return Object.keys(rollup).reduce((acc, serviceKey) => {
      acc[serviceKey].sparklineData = getSparklineDataRollup(acc[serviceKey].data);
      return acc;
    }, rollup);
  }

  // classifies virtual network ids by agent status (uninstalled, pending, private)
  @computed
  get agentBreakdown() {
    // @TODO azure does not currently offer an easy way to detect service health so prevent agent mgmt on them for now
    const supportedModels = this.models.filter((m) => m.get('cloudProvider') === 'aws');

    return getAgentBreakdown(
      supportedModels.map((model) => ({
        virtualNetworkId: model.get('virtualNetworkId'),
        agentStatus: model.get('agentModel')?.get('agent_status')
      }))
    );
  }

  // returns a list of unique agent ids
  // this is used to provide filtering in the agent management dialog so it shows only relevant pending/private agents
  @computed
  get agentIds() {
    return this.unfiltered.reduce((acc, model) => {
      const agentId = model.get('agentModel')?.get('id');

      if (agentId && !acc.includes(agentId)) {
        acc.push(agentId);
      }

      return acc;
    }, []);
  }

  // returns a list of virtual networks (id, region, name) that do not have an agent installed
  // this is used in the agent management dialog to populate the 'Uninstalled' tab
  @computed
  get uninstalledVirtualNetworks() {
    // @TODO azure does not currently offer an easy way to detect service health so prevent agent mgmt on them for now
    const supportedModels = this.unfiltered.filter((m) => m.get('cloudProvider') === 'aws');

    const models = supportedModels.reduce((acc, model) => {
      const virtualNetworkId = model.get('virtualNetworkId');

      if (!acc[virtualNetworkId] && !model.get('agentModel')) {
        acc[virtualNetworkId] = {
          virtualNetworkId,
          region: model.get('region'),
          vpcName: model.get('virtualNetworkName') // there are still dependencies on 'vpcName' terminology
        };
      }

      return acc;
    }, {});

    return Object.values(models);
  }

  // returns a list of unique test ids used for fetching test results
  @computed
  get serviceTestIds() {
    return this.unfiltered.reduce((acc, model) => {
      const testId = model.get('serviceTestModel')?.id;

      if (testId && !acc.includes(testId)) {
        acc.push(testId);
      }

      return acc;
    }, []);
  }

  // determines health status for each service, taking the most critical into consideration before all others
  @computed
  get serviceHealth() {
    return this.unfiltered.reduce((acc, model) => {
      const isAggregated = model.get('serviceTestResults.isAggregated');

      if (isAggregated) {
        // do not style health intents when using aggregated test results
        acc[model.get('service')] = 'none';
      } else {
        const lastHealth = acc[model.get('service')] || 'healthy';
        const nextHealth = model.get('serviceTestResults.overall_health') || 'healthy';

        acc[model.get('service')] = getWorstHealth(lastHealth, nextHealth) || 'none';
      }

      return acc;
    }, {});
  }

  // returns the percentage of models that have a valid service test
  @computed
  get testCoverage() {
    const covered = this.unfiltered.reduce((acc, model) => (acc += model.get('serviceTestModel') ? 1 : 0), 0);
    return this.unfilteredSize ? covered / this.unfilteredSize : 0;
  }

  @computed
  get missingTests() {
    return Object.values(
      this.unfiltered.reduce((acc, model) => {
        const test = getServiceTest({
          service: model.get('service'),
          region: model.get('region'),
          endpoint: model.get('endpoint'),
          testCollection: $syn.tests
        });

        if (test) {
          const serviceTestModel = model.get('serviceTestModel');

          // there is a test for this service/region
          if (serviceTestModel || model.isMissingTest) {
            // if this model already has a test OR it's flagged as missing a test, add it to the agents list
            const agent = model.get('agentModel');
            const testToAddOnTo = acc[test.config.name] || test;
            testToAddOnTo.config.agents.push(agent.id);

            if (model.isMissingTest) {
              testToAddOnTo.hasMissingAgents = true;
            }

            if (serviceTestModel) {
              testToAddOnTo.id = serviceTestModel.id;
              testToAddOnTo.test_status = serviceTestModel.get('test_status');
            }

            // clean out ping and trace configs
            delete testToAddOnTo.config.ping;
            delete testToAddOnTo.config.trace;

            acc[test.config.name] = testToAddOnTo;
          }
        }

        return acc;
      }, {})
    )
      .filter((test) => test.hasMissingAgents)
      .map(({ hasMissingAgents, ...test }) => test);
  }

  // caches all unique services we can use as filters in DE queries
  updateFilterableServices({ service, filters }) {
    const services = this.filterableServices[service] || [];

    filters.forEach((filter) => {
      if (filter !== '---' && !services.includes(filter)) {
        services.push(filter);
      }
    });

    this.filterableServices[service] = services;
  }

  /*
    Given a list of 'well-known' service names such as 'S3', returns a list of service names we can filter on such as 'S3.us-west-1'
  */
  getServiceNameFilterValues(serviceNames = []) {
    return serviceNames.reduce((acc, service) => acc.concat(this.filterableServices[service] || []), []).sort();
  }

  // updates the virtual network(s) the agent is associated with
  updateAgent(updatedAgent) {
    if (updatedAgent) {
      const virtualNetworkId = updatedAgent.get('metadata').cloud_vpc;

      if (virtualNetworkId) {
        this.unfiltered
          .filter((model) => model.get('virtualNetworkId') === virtualNetworkId)
          .forEach((model) => {
            model.set({ agent: updatedAgent });
          });
      }
    }
  }

  mergeTestResults({ status, tests_health } = {}) {
    if (status?.ok && tests_health) {
      const agentsAndTests = Object.entries(tests_health).reduce((acc, [testId, testResult]) => {
        // health data rolled up to the region level
        const regionHealth = Object.entries(testResult.health_agg).reduce((acc2, [key, value]) => {
          const { agg_health } = value;
          const lastHealth = agg_health[testResult.overall_health.time];

          if (key === 'http' || key === 'page-load') {
            return { ...lastHealth };
          }

          return acc2;
        }, {});

        // the health status of the test
        const overall_health = testResult.overall_health?.health || null;

        // get the current list of agent ids associated with the test
        const testAgentIds = $syn.tests.get(testId).get?.('config').agents || [];

        Object.entries(testResult.tasks).forEach(([taskId, task]) => {
          task.agents = Object.entries(task.agents).map(([agentId, agentOverallHealth]) => ({
            agent: testResult?.agents?.[agentId] || { id: agentId }, // bring in agent details
            overall_health: agentOverallHealth,
            health: Object.values(testResult.health_ts)
              .map(
                ({ tasks }) => tasks[taskId].agents[agentId] // get health by task/agent
              )
              .filter((value) => !!value)
          }));

          // create default agent health configs where they aren't coming back in the test results yet (flow first seen is null)
          const agents = testAgentIds.reduce((agentAcc, testAgentId) => {
            if (!agentAcc.find((agentConfig) => agentConfig.agent.id === testAgentId.toString())) {
              agentAcc.push({
                agent: { id: testAgentId },
                health: []
              });
            }
            return agentAcc;
          }, task.agents);

          agents.forEach((agentConfig) => {
            const { agent, health = [] } = agentConfig;
            const key = `${agent.id}-${testId}`;

            acc[key] = acc[key] || {
              // cache the whole test result on the model
              serviceTestResult: testResult,

              // pick the health stats we want to use/show
              serviceTestResults: {
                region: {
                  http_latency: { value: regionHealth.avg_latency, health: regionHealth.latency_health }
                },
                http_latency: null,
                http_status: null,
                overall_health,
                isAggregated: !!testResult?.book?.aggregationData?.isAggregated
              }
            };

            health.forEach((data) => {
              if (data.task_type === 'http' || data.task_type === 'page-load') {
                // capture the latency value/health
                acc[key].serviceTestResults.http_latency = {
                  value: data.avg_latency,
                  health: data.latency_health
                };

                // capture the http status/health
                acc[key].serviceTestResults.http_status = {
                  value: data.status,
                  health: data.latency_health
                };
              }
            });
          });
        });

        return acc;
      }, {});

      this.unfiltered.forEach((model) => {
        // set the 'serviceTestResult', and 'serviceTestResults' values on the model by its agent/test id key
        model.set(agentsAndTests[model.testResultsKey] || {});
      });
    }
  }

  // takes the first matching network interface id against the network interfaces map to get the service name for the connection
  getPrivateService(cloudProvider, srcInterfaceId, dstInterfaceId) {
    if (cloudProvider === 'aws' || cloudProvider === 'azure') {
      const { networkInterfaces } = this.networkInterfaceMap?.[cloudProvider] || {};
      const networkInterface = networkInterfaces[srcInterfaceId] || networkInterfaces[dstInterfaceId];

      if (networkInterface) {
        return {
          service: networkInterface.service,
          endpoint: networkInterface.endpoint.ServiceName
        };
      }
    }

    return {
      service: '---',
      endpoint: null
    };
  }

  getTrafficDirection(cloudProvider, item) {
    if (item.connectionType === 'public') {
      // for publicly linked services, the side that isn't empty (---)
      if (item.srcService !== '---') {
        return 'src';
      }

      if (item.dstService !== '---') {
        return 'dst';
      }
    } else if (cloudProvider === 'aws' || cloudProvider === 'azure') {
      // for privately linked services, the side that is defined in the network interfaces map
      const { networkInterfaces } = this.networkInterfaceMap?.[cloudProvider] || {};
      const { srcInterfaceId, dstInterfaceId } = item;

      if (networkInterfaces[srcInterfaceId]) {
        return 'src';
      }

      if (networkInterfaces[dstInterfaceId]) {
        return 'dst';
      }
    }

    return 'src';
  }

  // model keys are a combination of service, region, virtual network id, connection type, and endpoint
  getKeyValues(item) {
    const { trafficDirection: from, connectionType } = item;
    let to = from === 'src' ? 'dst' : 'src';

    if (item.connectionType === 'private') {
      // for private connections we'll use the same side
      to = from;
    }

    const service = item[`${from}Service`];
    const region = item[`${to}Region`];
    const virtualNetworkId = item[`${to}VirtualNetworkId`];
    const endpoint = item[`${to}Endpoint`] || null;

    return { service, region, virtualNetworkId, connectionType, endpoint };
  }

  // used as reference data when creating the final models we want represented in the collection
  getTrafficItem(resultItem) {
    // get the cloud provider from the item, this will help us determine the field names to look for
    const { cloudProvider } = resultItem;

    const trafficItem = {
      /*
        The src/dst cloud service dimension used to return just a pure service name such as:

        CLOUDFRONT
        S3
        ...

        Now they return a suffixed value with optional regional information including a special 'GLOBAL' region:

        CLOUDFRONT.GLOBAL
        S3
        S3.us-west-1
        ...

        Here we take the first part of the service name if it's in this new regionalized format. By doing this,
        we preserve the rollup to be just by the service and not broken down further by region
      */
      srcService: resultItem.src_cloud_service.split('.')[0],
      dstService: resultItem.dst_cloud_service.split('.')[0],
      srcRegion: resultItem[getDimensionFieldName({ cloudProvider, direction: 'src', type: 'region' })],
      dstRegion: resultItem[getDimensionFieldName({ cloudProvider, direction: 'dst', type: 'region' })],
      srcVirtualNetworkId:
        resultItem[getDimensionFieldName({ cloudProvider, direction: 'src', type: 'virtual_network_id' })],
      dstVirtualNetworkId:
        resultItem[getDimensionFieldName({ cloudProvider, direction: 'dst', type: 'virtual_network_id' })],
      srcInterfaceId: resultItem[getDimensionFieldName({ cloudProvider, direction: 'src', type: 'interface_id' })],
      dstInterfaceId: resultItem[getDimensionFieldName({ cloudProvider, direction: 'dst', type: 'interface_id' })],
      traffic: resultItem.avg_bits_per_sec,
      connectionType: 'public'
    };

    // determine private connection types
    if (trafficItem.srcService === '---' && trafficItem.dstService === '---') {
      trafficItem.connectionType = 'private';
    }

    if (trafficItem.connectionType === 'private') {
      // fill in the service name and raw endpoint using the match from the network interface map
      // private connection types will have their own endpoint in reverse dns form
      const { service, endpoint } = this.getPrivateService(
        cloudProvider,
        trafficItem.srcInterfaceId,
        trafficItem.dstInterfaceId
      );

      trafficItem.srcService = service;
      trafficItem.srcEndpoint = endpoint;

      trafficItem.dstService = service;
      trafficItem.dstEndpoint = endpoint;
    }

    // src | dst
    trafficItem.trafficDirection = this.getTrafficDirection(cloudProvider, trafficItem);

    // will be a key representing values for service, region, virtual network id, connection type, and endpoint
    const keyValues = this.getKeyValues(trafficItem);

    // get virtual network details (name and agent)
    const virtualNetworkDetail = this.networkInterfaceMap[cloudProvider]?.virtualNetworks[keyValues.virtualNetworkId];

    const agentModel = (virtualNetworkDetail?.agent && new AgentModel(virtualNetworkDetail.agent)) || null;
    let serviceTestModel = null;

    if (agentModel) {
      // get the official test name for the service
      const serviceTestName =
        getServiceTest({
          service: keyValues.service,
          region: keyValues.region,
          endpoint: keyValues.endpoint
        })?.config.name || null;

      const matchingServiceTestByName =
        agentModel.get('tests').find((test) => test.config.name === serviceTestName) || null;

      if (matchingServiceTestByName) {
        serviceTestModel = $syn.tests.models.find((t) => t.id === matchingServiceTestByName.id);
      }
    }

    return {
      ...trafficItem,
      ...keyValues,
      key: `${keyValues.service}-${keyValues.region}-${keyValues.virtualNetworkId}-${keyValues.connectionType}-${keyValues.endpoint}`,
      virtualNetworkName: virtualNetworkDetail?.name || keyValues.virtualNetworkId,
      agentModel,
      serviceTestModel,
      cloudProvider
    };
  }

  // aggregates a traffic query result to represent a unique set of models based on service/region/virtualNetworkId/connection type
  deserialize(response) {
    // reset the filterable services cache
    this.filterableServices = {};

    const data = response.reduce((acc, model) => {
      const trafficItem = this.getTrafficItem(model);

      // do not include virtual networks without a name or id
      if (trafficItem.virtualNetworkName === '---') {
        return acc;
      }

      // merge in unique, filterable service names
      this.updateFilterableServices({
        service: trafficItem.service,
        filters: [model.src_cloud_service, model.dst_cloud_service]
      });

      // initialize the final model we want to store in the collection
      const currentItem = acc[trafficItem.key] || {
        key: trafficItem.key,
        service: trafficItem.service,
        region: trafficItem.region,
        virtualNetworkId: trafficItem.virtualNetworkId,
        virtualNetworkName: trafficItem.virtualNetworkName,
        connectionType: trafficItem.connectionType,
        endpoint: trafficItem.endpoint,
        agentModel: trafficItem.agentModel,
        serviceTestModel: trafficItem.serviceTestModel,
        cloudProvider: trafficItem.cloudProvider,
        fromTraffic: 0,
        toTraffic: 0,
        rawData: {
          from: {},
          to: {}
        },
        sparklineData: {
          from: [],
          to: []
        }
      };

      // roll up traffic stats
      if (trafficItem.trafficDirection === 'src') {
        currentItem.fromTraffic += trafficItem.traffic;
      } else {
        currentItem.toTraffic += trafficItem.traffic;
      }

      if (model.timeSeries) {
        const direction = trafficItem.trafficDirection === 'src' ? 'from' : 'to';

        // add values to the raw dataset
        model.timeSeries.both_bits_per_sec.flow.forEach(([timestamp, value]) => {
          currentItem.rawData[direction][timestamp] = (currentItem.rawData[direction][timestamp] || []).concat(value);
        });

        // generate sparklines
        currentItem.sparklineData[direction] = getSparklineData(currentItem.rawData[direction]);
      }

      return {
        ...acc,
        [trafficItem.key]: currentItem
      };
    }, {});

    return Object.values(data);
  }
}

export default PerfMonitorServiceCollection;
