import Collection from 'core/model/Collection';
import { action } from 'mobx';

import RouteTableSummaryModel from './RouteTableSummaryModel';

class RouteTableSummaryCollection extends Collection {
  get model() {
    return RouteTableSummaryModel;
  }

  @action
  getFilterValues(model) {
    const modelData = model.toJS();
    return [...Object.values(modelData), ...Object.values(model.get('properties'))];
  }
}

export default RouteTableSummaryCollection;
