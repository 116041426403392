import Collection from 'core/model/Collection';
import { isIpValid } from 'core/util/ip';

class SecurityRulesCollection extends Collection {
  modelType = null;

  filter(query) {
    if (query !== undefined) {
      this.filterState = query;

      const validIp = isIpValid(query, { returnAddress: true });

      if (validIp) {
        this.models.replace(
          this.original.filter((model) => {
            const resource = model.get('resource');
            const modelIp = isIpValid(resource, { returnAddress: true });

            return modelIp && validIp.isInSubnet(modelIp);
          })
        );

        this.sort();

        return this.models;
      }
    }

    return super.filter(query);
  }

  deserialize(data) {
    const { securityGroup, nacl } = data;

    if (securityGroup) {
      this.modelType = 'securityGroup';
      return securityGroup.rules;
    }

    if (nacl) {
      this.modelType = 'nacl';
      return nacl.rules;
    }

    return data;
  }
}

export default SecurityRulesCollection;
