import { computed } from 'mobx';
import Model from 'core/model/Model';
import { toIp } from 'core/util/ip';

export default class RouteTableSummaryModel extends Model {
  get sortValues() {
    return {
      destination: () => this.destinationIp?.addressMinusSuffix,
      routeTarget: () => {
        const { attachment, nextHopResource, resourceType } = this.get('routeTarget') || {};
        return `${attachment?.name}${nextHopResource?.name}${resourceType}`;
      }
    };
  }

  @computed
  get destinationIp() {
    return toIp(this.get('destination') || this.get('DestinationCidrBlock'));
  }

  @computed
  get isAwsCoreNetworkRoute() {
    return this.get('EdgeLocation') && this.get('SegmentName');
  }

  @computed
  get isTransitGatewayRouteTable() {
    const routeTarget = this.get('routeTarget');
    return routeTarget && routeTarget.attachment;
  }
}
