import { computed } from 'mobx';
import Model from 'core/model/Model';
import { parseDimensionIp } from 'core/util/ip';
import parseServiceString from 'core/util/parseServiceString';

export default class DeniedTrafficModel extends Model {
  getSortValue(field) {
    if (field === 'source') {
      return this.source;
    }

    if (field === 'destination') {
      return this.destination;
    }

    return super.getSortValue(field);
  }

  @computed
  get sourceIp() {
    return parseDimensionIp(this.get('inet_src_addr'));
  }

  @computed
  get sourcePortAndProtocol() {
    return parseServiceString(this.get('i_l4_src_port_name'));
  }

  @computed
  get destinationIp() {
    return parseDimensionIp(this.get('inet_dst_addr'));
  }

  @computed
  get destinationPortAndProtocol() {
    return parseServiceString(this.get('i_l4_dst_port_name'));
  }

  @computed
  get source() {
    const { port, protocol } = this.sourcePortAndProtocol;
    return `${this.sourceIp}:${port}/${protocol}`;
  }

  @computed
  get destination() {
    const { port, protocol } = this.destinationPortAndProtocol;
    return `${this.destinationIp}:${port}/${protocol}`;
  }
}
