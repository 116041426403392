import { computed } from 'mobx';
import Model from 'core/model/Model';
import { toIp } from 'core/util/ip';

export default class RouteTableSummaryModel extends Model {
  get sortValues() {
    return {
      properties: () => {
        const { nextHopType, nextHopIpAddress } = this.get('properties') || {};
        return `${nextHopType}${nextHopIpAddress}`;
      }
    };
  }

  @computed
  get destinationIp() {
    return toIp(this.get('addressPrefix'));
  }
}
