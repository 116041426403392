import { action } from 'mobx';
import { filterStateIpFilter } from 'app/util/cloud';
import PagedCollection from 'core/model/PagedCollection';

class SubscriptionFilterCollection extends PagedCollection {
  @action
  filter(query, options = {}) {
    if (query !== undefined) {
      this.filterState = query;
    }

    if (!this.filterState) {
      return super.filter(query, options);
    }

    const { mostSpecificMatches, higherSpecificityMatches } = filterStateIpFilter(
      this.filterState,
      this.original,
      'ips'
    );

    this.models.replace([...mostSpecificMatches, ...higherSpecificityMatches]);
    this.sort();
    return this.models;
  }
}

export default SubscriptionFilterCollection;
