import { PLATFORM_TYPES } from 'app/stores/mitigations/mitigationsConstants';
import { operators } from 'app/util/alerting/transformPolicyFilters';

export function transformMitigationPredicateToFilter(predicate, mitigationDetailsToFilterKeysMap, flowspecKey) {
  const filterField = mitigationDetailsToFilterKeysMap[flowspecKey];
  let finalOperator = operators[predicate.operator];
  let finalValue = predicate.value;

  // filters do not support more complex operators so we simplify the condition
  // for example: >=50 becomes >49, <=50 becomes <51

  if (finalOperator === '<=') {
    finalOperator = '<';
    finalValue = `${parseInt(predicate.value) + 1}`;
  } else if (finalOperator === '>=') {
    finalOperator = '>';
    finalValue = `${parseInt(predicate.value) - 1}`;
  }

  if (filterField) {
    return {
      filterField,
      operator: finalOperator,
      filterValue: finalValue
    };
  }

  return null;
}

// Returns array of method types to disable based on policy dimensions provided
export function getDisabledMethodTypesForDimensions(dimensions) {
  if (dimensions.includes('IP_dst') || dimensions.includes('IP_src')) {
    return [];
  }

  // Exclude everything but cfmt & flowspec if no IP dimensions provided
  return PLATFORM_TYPES.map((type) => type.value).filter((type) => !['cfmt', 'flowspec'].includes(type));
}

export function isMethodSupportedByDimensions(dimensions = [], mitigationMethod, fieldMap) {
  // If flowspec, only need to check against inferred dimensions
  if (mitigationMethod?.type === 'flowspec') {
    const fields = mitigationMethod.get('flowspec');
    return (
      Object.keys(fields).reduce((missing, flowspecFieldKey) => {
        const field = fields[flowspecFieldKey];

        if (field?.infer && field?.enabled) {
          // if a dimension is inferred, we need to make sure it's also on the policy
          if (!dimensions.includes(fieldMap[flowspecFieldKey])) {
            missing.push(flowspecFieldKey);
          }
        }

        return missing;
      }, [])?.length === 0
    );
  }

  // Free pass if: method is missing; method is cfmt; IP dims present

  return (
    !mitigationMethod ||
    mitigationMethod?.type === 'cfmt' ||
    dimensions.includes('IP_dst') ||
    dimensions.includes('IP_src')
  );
}
