export const INSIGHT_TYPES = {
  HOP_COUNT: 'test.synthetics.latency.hopcount',
  FAIL_TARGET: 'test.synthetics.failure.target'
};

export const INSIGHT_SEVERITIES = ['critical', 'severe', 'major', 'warning', 'minor', 'notice'];

export const INSIGHT_SEVERITY_LABELS = {
  critical: 'Critical',
  severe: 'Severe',
  major: 'Major',
  warning: 'Warning',
  minor: 'Minor',
  notice: 'Notice'
};

export const INSIGHT_SEVERITY_COLORS = {
  critical: 'severity.critical',
  severe: 'severity.major2',
  major: 'severity.major',
  warning: 'severity.minor2',
  minor: 'severity.minor',
  notice: 'severity.baseline'
};

export const INSIGHT_SEVERITY_RANKS = {
  critical: 5,
  severe: 4,
  major: 3,
  warning: 2,
  minor: 1,
  notice: 0
};
