import Collection from 'core/model/Collection';
import DashboardModel from 'app/stores/dashboard/DashboardModel';
import SavedView from 'app/stores/savedView/SavedView';

class LibraryCollection extends Collection {
  fetch = () =>
    // collection is not fetchable
    Promise.resolve();

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  build = (attributes, options = {}) => {
    const { select = false, deserialize = true } = options;
    const isDashboard = attributes.type === 'dashboard' || attributes.dash_title;
    const ModelClass = isDashboard ? DashboardModel : SavedView;
    const model = new ModelClass();

    model.store = this.store;
    model.collection = this;
    model.set(deserialize ? model.deserialize(attributes) : attributes);

    if (select) {
      this.selected = model;
    }

    return model;
  };
}

export default LibraryCollection;
