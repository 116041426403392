import { action, computed } from 'mobx';
import PagedCollection from 'core/model/PagedCollection';

const VIEW_DROPDOWN_DISCREET_FILTER_NAME = 'view-accounts-dropdown';

class AccountsFilterCollection extends PagedCollection {
  @action
  filter(query = undefined, options = {}) {
    if (query !== undefined) {
      this.resetDiscreteFilters();
      super.filter(query, options);
      this.applyPagination();
    }

    if (query === undefined) {
      super.filter(query, options);
    }

    return this.models;
  }

  getSortValue = (field, model) => {
    const value = model.get(field);
    return value;
  };

  @computed
  get selectedAccountIds() {
    return this.selected?.map((model) => model.get('id')) ?? [];
  }

  @action
  showAllAccounts() {
    this.removeDiscreteFilter(VIEW_DROPDOWN_DISCREET_FILTER_NAME);

    this.applyPagination();
  }

  @action
  selectAccounts(accountIds) {
    this.clearSelection();

    accountIds.forEach((id) => {
      this.get(id)?.select({ multi: true });
    });

    this.applyPagination();
  }

  @action
  addGroupFilter(accountIds) {
    this.selectAccounts(accountIds);
    this.addDiscreteFilter({
      type: 'group-dropdown',
      fn: (subscriptionModel) => accountIds.includes(subscriptionModel.get('id'))
    });

    this.applyPagination();
  }

  @action
  removeGroupFilter() {
    this.removeDiscreteFilter('group-dropdown');

    this.applyPagination();
  }

  @action
  showSelectedAccounts() {
    this.addDiscreteFilter({
      type: VIEW_DROPDOWN_DISCREET_FILTER_NAME,
      fn: (subscriptionModel) => subscriptionModel.isSelected
    });

    this.applyPagination();
  }

  @action
  selectAll(options) {
    // when selecting all, unselect currently selected models to ensure everything is selected
    if (this.selectedSize > 0 && !this.isAllSelected) {
      this.clearSelection();
    }

    this.original.forEach((model) => {
      model.select(options);
    });
  }

  applyPagination() {
    this.calculatePageCount();
    this.applyPageChangeFilter();
  }

  @action
  reset(options = {}) {
    super.reset(options);
    this.accountsCollection.clearSelection();
  }

  @action
  resetDiscreteFilters() {
    super.resetDiscreteFilters();
    this.applyPagination();
  }
}

export default AccountsFilterCollection;
