import Model from 'core/model/Model';
import { action, computed } from 'mobx';

export default class RbacPermModel extends Model {
  @action
  setLabelBasedAccess(newValue) {
    this.set('labelBasedAccess', newValue);
    if (!newValue) {
      this.set('label_metadata', []);
    }
  }

  @computed
  get isLabelBasedAccess() {
    if (this.get('labelBasedAccess') !== undefined) {
      return this.get('labelBasedAccess');
    }

    if (this.get('label_metadata')?.length) {
      return true;
    }
    return false;
  }
}
