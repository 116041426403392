import { computed } from 'mobx';
import { groupBy } from 'lodash';
import getAgentBreakdown from 'app/views/cloudPerformance/utils/getAgentBreakdown';
import CloudMapCollection from './CloudMapCollection';

class PerfMonitorNodeCollection extends CloudMapCollection {
  interconnectsData = null;

  get url() {
    return '/api/ui/topology/cloud-interconnects/aws?include_synth=true';
  }

  deserialize = (response) => {
    this.interconnectsData = response;

    return response.nodes;
  };

  @computed
  get nodeList() {
    return this.original.map((m) => {
      const data = m.get();
      const matchesFilter = !!this.models.find((model) => model.id === data.id);
      const node = m.get();

      if (this.filterState) {
        node.matchesFilter = matchesFilter;
      }

      return node;
    });
  }

  @computed
  get nodes() {
    const byPathType = groupBy(this.nodeList, 'pathType');

    return {
      siteToSite: byPathType.siteToSite ? this.groupNodes(byPathType.siteToSite) : { columnCount: 0 },
      directConnect: byPathType.directConnect ? this.groupNodes(byPathType.directConnect) : { columnCount: 0 }
    };
  }

  @computed
  get nodeIdsMatchingFilter() {
    return this.nodeList.reduce((acc, node) => {
      if (node.matchesFilter) {
        return acc.concat(node.id);
      }

      return acc;
    }, []);
  }

  @computed
  get linkPathIdsMatchingFilter() {
    return this.nodeList.reduce((acc, node) => {
      if (node.matchesFilter) {
        return acc.concat(`${node.pathType}-${node.id}`);
      }

      return acc;
    }, []);
  }

  // determines the vpc ids agent status between uninstalled, pending, and private (installed and activated)
  @computed
  get agentBreakdown() {
    return getAgentBreakdown(
      this.unfiltered
        .filter((model) => model.get('type') === 'Vpc')
        .map((model) => ({
          vpcId: model.get('VpcId'),
          agentStatus: model.get('agent')?.agent_status
        }))
    );
  }

  // a list of agent ids used to filter the agent management dialog in showing matches for the existing pending and private agents
  @computed
  get agentIds() {
    return this.unfiltered.reduce((acc, model) => {
      const agentId = model.get('agent')?.id;

      if (agentId && !acc.includes(agentId)) {
        acc.push(agentId);
      }

      return acc;
    }, []);
  }

  // a list of vpc fragments that do not have agents
  // this is used to populate the 'Uninstalled' tab in the agent management dialog
  @computed
  get uninstalledVpcs() {
    const models = this.unfiltered.reduce((acc, model) => {
      if (model.get('type') === 'Vpc') {
        const vpcId = model.get('VpcId');

        if (!acc[vpcId] && !model.get('agent')) {
          acc[vpcId] = {
            vpcId,
            region: model.get('region'),
            vpcName: model.get('metadata')?.name || vpcId
          };
        }
      }

      return acc;
    }, {});

    return Object.values(models);
  }

  // updates a vpc with the matching agent in-place after editing
  updateAgent(updatedAgent) {
    if (updatedAgent) {
      const vpcId = updatedAgent.get('metadata').cloud_vpc;

      if (vpcId) {
        this.unfiltered
          .filter((model) => model.get('VpcId') === vpcId)
          .forEach((model) => {
            model.set({ agent: updatedAgent.get() });
          });
      }
    }
  }

  groupNodes(nodeData) {
    const groupedNodeData = nodeData.reduce(
      (acc, entity) => {
        const groupKey = entity.type;

        if (entity.region) {
          acc.regions[entity.region] = {
            ...acc.regions[entity.region],
            [entity.id]: entity
          };
        } else if (!acc[groupKey]) {
          acc[groupKey] = [entity];
        } else if (!acc[groupKey].find((groupEntity) => groupEntity.id === entity.id)) {
          acc[groupKey].push(entity);
        }

        return acc;
      },
      {
        regions: {}
      }
    );

    return this.toColumns({
      ...groupedNodeData,
      regions: this.groupRegionalEntities(groupedNodeData.regions)
    });
  }

  groupRegionalEntities(regions) {
    if (regions) {
      return Object.keys(regions).reduce(
        (acc, regionName) => ({
          ...acc,
          [regionName]: groupBy(Object.values(regions[regionName]), 'type')
        }),
        {}
      );
    }

    return null;
  }

  // assembles the node groups into the proper renderable order of entities
  toColumns(nodeData) {
    const { TransitGateway = [], VpnGateway = [], ...restNodeTypes } = nodeData;
    const nodeMap = {
      ...restNodeTypes,
      gateways: [].concat(TransitGateway, VpnGateway)
    };

    return [
      'regions',
      'Vpc',
      'gateways',
      'DirectConnectGateway',
      'DirectConnection',
      'VpnConnection',
      'CustomerGateway',
      'VirtualInterface',
      'Router'
    ].reduce(
      (acc, columnName) => {
        const data = nodeMap[columnName];

        if (data) {
          if (columnName === 'regions') {
            const regionNames = Object.keys(data);

            if (regionNames.length > 0) {
              // regions take up their own column
              acc.columnCount += 1;

              acc.regions = regionNames.reduce((regionAcc, regionName) => {
                regionAcc[regionName] = this.toColumns(data[regionName]);
                return regionAcc;
              }, {});
            }
          } else if (data.length > 0) {
            // add a column for this node group
            acc.columnCount += 1;

            acc.nodeGroups = acc.nodeGroups || [];
            acc.nodeGroups.push(data);
          }
        }

        return acc;
      },
      { columnCount: 0 }
    );
  }
}

export default PerfMonitorNodeCollection;
