import { action, observable } from 'mobx';
import PagedCollection from 'core/model/PagedCollection';
import { isIpV4Valid, isIpV6Valid, toIp } from 'core/util/ip';
import RouteTableSummaryModel from './RouteTableSummaryModel';

class RouteTableSummaryCollection extends PagedCollection {
  @observable
  pageState = { offset: 0, pageCount: 1, currentPage: 1, perPage: 7, totalCount: 0 };

  get model() {
    return RouteTableSummaryModel;
  }

  get defaultDiscreteFilters() {
    return [
      {
        type: 'exclude-specific-routes',
        fn: (routeTableSummaryModel) => {
          if (routeTableSummaryModel.isAwsCoreNetworkRoute && routeTableSummaryModel.get('destination')) {
            return true;
          }

          return !routeTableSummaryModel.get('parent');
        }
      }
    ];
  }

  getFilterValues(model) {
    const routeTable = model.get('routeTable');
    const parent = model.get('parent');
    const state = model.get('state');
    const routeTarget = model.get('routeTarget');
    const values = [routeTable?.copyValue ?? parent?.routeTable?.copyValue ?? '', state];

    if (typeof routeTarget === 'string') {
      values.push(routeTarget);
    }

    if (routeTarget) {
      if (routeTarget.attachment) {
        values.push(routeTarget.attachment.copyValue);
      }

      if (routeTarget.nextHopResource) {
        values.push(routeTarget.nextHopResource.copyValue);
      }
    }

    return values;
  }

  @action
  filter(query, options = {}) {
    if (query !== undefined) {
      this.filterState = query;
    }

    if (this.filterState) {
      const containsDot = this.filterState.includes('.');
      const containsColon = this.filterState.includes(':');

      let ip4;
      let ip6;

      if (containsDot || !Number.isNaN(Number.parseInt(this.filterState, 10))) {
        const inputAddress = this.filterState.replace(/(\/.*|\.)$/, '');
        const groups = inputAddress.split('.');
        const mask = this.filterState.match(/\/(\d+)$/)?.[1] || groups.length * 8;

        if (groups.length <= 4) {
          for (let i = 0; i < 4; i += 1) {
            groups[i] = groups[i] || '0';
          }

          ip4 = isIpV4Valid(`${groups.join('.')}/${mask}`, { returnAddress: true });
        }
      }

      if (containsColon || !Number.isNaN(Number.parseInt(this.filterState, 16))) {
        let inputAddress = this.filterState.replace(/(\/.*|:)$/, '');
        let mask = this.filterState.match(/\/(\d+)$/)?.[1] || 128;
        const groups = inputAddress.split(':');

        if (groups.length < 8 && !this.filterState.includes('::')) {
          inputAddress += '::';
          mask = groups.length * 16;
        }

        ip6 = isIpV6Valid(`${inputAddress}/${mask}`, { returnAddress: true });
      }

      if (ip4 || ip6) {
        const specificMatches = []; // [{ model, ip }]
        let highestSpecificity = 0;
        const higherSpecificityMatches = [];

        this.original.forEach((model) => {
          const destination = model.get('destination');
          const destinationIp = toIp(destination);

          const recordMatches = (inputIp) => {
            const inputInDestination = inputIp.isInSubnet(destinationIp);
            const destinationInInput = destinationIp.isInSubnet(inputIp);

            if (inputInDestination) {
              // record specificMatches which is where input is subset of destination
              specificMatches.push({ model, ip: destinationIp });
              highestSpecificity = Math.max(highestSpecificity, destinationIp.subnetMask);
            } else if (destinationInInput) {
              // record matches where destination is subset of input.
              // This represents matches that might be possible as user further specifies input
              higherSpecificityMatches.push(model);
            }
          };

          if (destinationIp) {
            if (ip4 && destinationIp.v4) {
              recordMatches(ip4);
            }

            if (ip6 && !destinationIp.v4) {
              recordMatches(ip6);
            }
          }
        });

        const mostSpecificMatches = specificMatches.reduce((arr, { model, ip }) => {
          // filter out specificMatches that have lower specificity than the highestSpecificity, as defined
          // by subnetMask value
          if (ip.subnetMask >= highestSpecificity) {
            arr.push(model);
          }
          return arr;
        }, []);
        this.models.replace([...mostSpecificMatches, ...higherSpecificityMatches]);
        this.sort();
        return this.models;
      }
    }

    return super.filter(query, options);
  }
}

export default RouteTableSummaryCollection;
