import { action } from 'mobx';
import Collection from 'core/model/Collection';
import { filterStateIpFilter } from 'app/util/cloud';

import RouteDestinationModel from './RouteDestinationModel';

class RouteDestinationCollection extends Collection {
  get model() {
    return RouteDestinationModel;
  }

  @action
  getFilterValues(model) {
    const modelData = model.toJS();
    return Object.values(modelData);
  }

  @action
  filter(query, options = {}) {
    if (query !== undefined) {
      this.filterState = query;
    }

    if (!this.filterState) {
      return super.filter(query, options);
    }

    const { mostSpecificMatches, higherSpecificityMatches } = filterStateIpFilter(
      this.filterState,
      this.original,
      'destination'
    );

    this.models.replace([...mostSpecificMatches, ...higherSpecificityMatches]);
    this.sort();
    return this.models;
  }
}

export default RouteDestinationCollection;
