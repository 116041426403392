import React, { Fragment } from 'react';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import moment from 'moment';
import $metrics from 'app/stores/metrics/$metrics';
import safelyParseJSON from 'core/util/safelyParseJson';
import { buildFilterGroup } from 'core/util/filters';

import {
  POLICY_MODES,
  POLICY_APPLICATIONS,
  RECON_POLICY_SUBTYPES,
  MAX_KEYS_PER_POLICY,
  ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY,
  EVENT_POLICY_TYPES
} from 'shared/alerting/constants';
import $auth from 'app/stores/$auth';

export function serializeBaselinePercentValue(decrease) {
  const value = Number(decrease);
  if (value >= 100) {
    return 10000;
  }
  return (1 / (100 - value)) * 10000 - 100;
}

export function deserializeBaselinePercentValue(increase) {
  const value = Number(increase);
  return Math.round((100 * value) / (value + 100));
}

export function displayRawBaselinePercentValue(rawValue, direction) {
  const directionLabel = direction === 'currentToHistory' ? 'above' : 'below';

  let value = rawValue - 100;

  if (direction === 'historyToCurrent') {
    value = deserializeBaselinePercentValue(rawValue);
  }

  return `${value}% ${directionLabel} baseline`;
}

export function getPolicyExpireDate(days) {
  return moment().utc().add(days, 'days').toISOString();
}

export function isSilentMode(expireDate) {
  return expireDate ? moment(expireDate).isAfter() : false;
}

export function getSilentModeDate(expireDate) {
  return isSilentMode(expireDate) ? moment.utc(expireDate).toISOString() : moment.utc().add(7, 'days').toISOString();
}

export function isReflectionAttackPolicy(name) {
  return /^(DDoS - |V4 DDoS - )?Amplification and Reflection Attack$/.test(name);
}

export function isReflectionAttackPortPolicy(name) {
  return /^(DDoS - |V4 DDoS - )?Amplification and Reflection Attack UDP$/.test(name);
}

export function hasSubMinuteEvaluationPeriod(evaluationPeriod) {
  if (!evaluationPeriod) {
    return false;
  }
  if (typeof evaluationPeriod === 'string') {
    return Number(evaluationPeriod.replace('s', '')) < 60;
  }
  return evaluationPeriod < 60;
}

export function formatEvaluationPeriod(evaluationPeriod) {
  return typeof evaluationPeriod === 'string'
    ? evaluationPeriod.replace('s', ' seconds')
    : `${evaluationPeriod} seconds`;
}

/**
 * Preset   Percentile   Keys  Baseline Window Start   Baseline Trailing Window    Baseline minimum data
 * Express     98th       25           1 hour                   1 weeks                 2 Days
 * Default     98th       25           1 day                    4 weeks                 4 Days
 * Precision   98th      300           1 day                    4 weeks                 28 Days
 */

/**
 Preset    Comparison Data (bucket) 	Baseline derivation 	Baseline Precision	Toggle: Weekdays
 Express      every hour                current baseline	         98th	              No
 Default      every hour                current baseline 	         98th	              No
 Precision    every hour                current baseline	         98th             	No
 */

export function getExpressBaselineOverallSettingValues() {
  return {
    nTopKeysAddedToBaseline: '25',
    nTopKeysEvaluated: '25',
    'baselineSettings.datapointAggregationMethod': 'REL_P98',
    'baselineSettings.startOffset': 3600,
    'baselineSettings.length': 604800,
    'baselineSettings.minimalLength': 2,
    'baselineSettings.minimal_length_unit': 'days',
    'baselineSettings.seasonality': 'hourly',
    'baselineSettings.neighbourhoodAggregationMethod': '',
    'baselineSettings.neighbourhoodRadius': 1,
    'baselineSettings.comparisonValueAggregateFunction': 'REL_P95',
    'baselineSettings.weekendAware': false
  };
}

export function getDefaultBaselineOverallSettingValues() {
  return {
    nTopKeysAddedToBaseline: '25',
    nTopKeysEvaluated: '25',
    'baselineSettings.datapointAggregationMethod': 'REL_P98',
    'baselineSettings.startOffset': 86400,
    'baselineSettings.length': 2419200,
    'baselineSettings.minimalLength': 4,
    'baselineSettings.minimal_length_unit': 'days',
    'baselineSettings.seasonality': 'daily',
    'baselineSettings.neighbourhoodAggregationMethod': '',
    'baselineSettings.neighbourhoodRadius': 1,
    'baselineSettings.comparisonValueAggregateFunction': 'REL_P95',
    'baselineSettings.weekendAware': false
  };
}

export function getPrecisionBaselineOverallSettingValues() {
  return {
    nTopKeysAddedToBaseline: '300',
    nTopKeysEvaluated: '300',
    'baselineSettings.datapointAggregationMethod': 'REL_P98',
    'baselineSettings.startOffset': 86400,
    'baselineSettings.length': 2419200,
    'baselineSettings.minimalLength': 14,
    'baselineSettings.minimal_length_unit': 'days',
    'baselineSettings.seasonality': 'daily',
    'baselineSettings.neighbourhoodAggregationMethod': '',
    'baselineSettings.neighbourhoodRadius': 1,
    'baselineSettings.comparisonValueAggregateFunction': 'REL_P95',
    'baselineSettings.weekendAware': false
  };
}

export function getBaselineOverallSettingValues(overallSetting) {
  switch (overallSetting) {
    case 'express':
      return getExpressBaselineOverallSettingValues();
    case 'default':
      return getDefaultBaselineOverallSettingValues();
    case 'precision':
      return getPrecisionBaselineOverallSettingValues();
    default:
      return {};
  }
}

export function doesPolicyBaselineMatchDefaults(policy, defaults) {
  const policyBaselineValues = {
    nTopKeysAddedToBaseline: `${policy.nTopKeysAddedToBaseline}`,
    nTopKeysEvaluated: `${policy.nTopKeysEvaluated}`,
    'baselineSettings.datapointAggregationMethod': policy.baselineSettings.datapointAggregationMethod,
    'baselineSettings.startOffset': policy.baselineSettings.startOffset,
    'baselineSettings.length': policy.baselineSettings.length,
    'baselineSettings.minimalLength': policy.baselineSettings.minimalLength,
    'baselineSettings.minimal_length_unit': policy.baselineSettings.minimal_length_unit,
    'baselineSettings.seasonality': policy.baselineSettings.seasonality,
    'baselineSettings.neighbourhoodAggregationMethod': policy.baselineSettings.neighbourhoodAggregationMethod,
    'baselineSettings.neighbourhoodRadius': policy.baselineSettings.neighbourhoodRadius,
    'baselineSettings.comparisonValueAggregateFunction': policy.baselineSettings.comparisonValueAggregateFunction,
    'baselineSettings.weekendAware': policy.baselineSettings.weekendAware
  };

  return isEqual(defaults, policyBaselineValues);
}

export function isExpressBaselineOverallSetting(policy) {
  return doesPolicyBaselineMatchDefaults(policy, getExpressBaselineOverallSettingValues());
}

export function isDefaultBaselineOverallSetting(policy) {
  return doesPolicyBaselineMatchDefaults(policy, getDefaultBaselineOverallSettingValues());
}

export function isPrecisionBaselineOverallSetting(policy) {
  return doesPolicyBaselineMatchDefaults(policy, getPrecisionBaselineOverallSettingValues());
}

export function getBaselineOverallSetting(policy) {
  if (isExpressBaselineOverallSetting(policy)) {
    return 'express';
  }

  if (isDefaultBaselineOverallSetting(policy)) {
    return 'default';
  }

  if (isPrecisionBaselineOverallSetting(policy)) {
    return 'precision';
  }

  return '';
}

export function getBaselineMinLookbackSeconds(value, unit) {
  if (unit === 'hours') {
    return (parseInt(value) || 0) * 60 * 60;
  }

  // it's days
  return (parseInt(value) || 0) * 60 * 60 * 24;
}

export function getBaselineMinLookbackValues(value) {
  try {
    const numericValue = Number(value.replace('s', ''));
    const isDays = numericValue % (60 * 60 * 24) === 0;
    const finalValue = Math.floor(isDays ? numericValue / (60 * 60 * 24) : numericValue / (60 * 60));

    return {
      minimal_length_unit: isDays ? 'days' : 'hours',
      minimalLength: finalValue
    };
  } catch (e) {
    return {
      minimal_length_unit: 'days',
      minimalLength: 4
    };
  }
}

export function getSummarySelectLabel(field, value, options) {
  value = value || field.getValue();
  const option = (options || field.options).find((o) => o.value === value);

  return option && option.label ? option.label : option;
}

export function getSummarySelectLabels(field, evaluateNumericalIds, options) {
  const labels = [];
  const opts = options || field.options;

  let values = field.getValue();

  if (values === null || values === undefined) {
    values = [];
  }

  if (!Array.isArray(values)) {
    const item = getSummarySelectLabel(field, values, options);

    if (item) {
      labels.push(item);
    }
  } else {
    values.forEach((value) => {
      const item =
        opts &&
        opts.filter((option) => {
          value = typeof value === 'object' ? value.value : value;
          if (evaluateNumericalIds) {
            return option.value === parseInt(value);
          }
          return option.value === value;
        })[0];

      if (item) {
        labels.push(item);
      }
    });
  }

  return (
    <Fragment>
      {labels.map((item) => (
        <Fragment key={uniqueId()}>
          {item.label && item.label}
          {!item.label && item}
        </Fragment>
      ))}
      {labels.length === 0 && 'None'}
    </Fragment>
  );
}

function getReconPolicyMetadata(policy) {
  // PolicyModel
  if (policy?.get) {
    return policy.get('applicationMetadata', {});
  }
  const { applicationMetadata = {} } = policy;
  // AlertModel
  if (isString(applicationMetadata)) {
    return safelyParseJSON(applicationMetadata);
  }
  // Policy plain object
  return applicationMetadata;
}

export function isDeviceStateChangePolicy(policy) {
  const { subtype } = getReconPolicyMetadata(policy);
  return subtype === RECON_POLICY_SUBTYPES.DEVICES;
}

export function isInterfaceStateChangePolicy(policy) {
  const { subtype } = getReconPolicyMetadata(policy);
  return subtype === RECON_POLICY_SUBTYPES.INTERFACES;
}

export function isBGPNeighborStateChangeAlert(policy) {
  const { subtype } = getReconPolicyMetadata(policy);
  return subtype === RECON_POLICY_SUBTYPES.BGP_NEIGHBORS;
}

export function isCustomStateChangeAlert(policy) {
  const { subtype } = getReconPolicyMetadata(policy);
  return subtype === RECON_POLICY_SUBTYPES.CUSTOM;
}

export function isEventPolicy(application) {
  return application === POLICY_APPLICATIONS.KEVENT;
}

export function isSyslogEventPolicy(dimensions) {
  return dimensions.some((dimension) => dimension.includes(`__event_${EVENT_POLICY_TYPES.SYSLOG}__`));
}

export function isSnmpTrapEventPolicy(dimensions) {
  return dimensions.some((dimension) => dimension.includes(`__event_${EVENT_POLICY_TYPES.SNMP_TRAP}__`));
}

export function getPolicyManageUrl(policyType, policyId, isToggleMode) {
  const pathname = '/v4/alerting/policies';
  const id = policyId || '';

  if (policyType === POLICY_APPLICATIONS.METRIC) {
    return `${pathname}/nms/${isToggleMode ? 'updown' : 'threshold'}/${id}`;
  }

  if (policyType === POLICY_APPLICATIONS.DDOS) {
    policyType = 'protect';
  }

  if (policyType === POLICY_APPLICATIONS.CORE) {
    policyType = 'traffic';
  }

  if (policyType === POLICY_APPLICATIONS.KEVENT) {
    policyType = 'nms';
  }

  return `${pathname}/${policyType}/${id}`;
}

export function getMetricPolicyChartingConfig(metricConfig, queryConfig = {}) {
  const metricOptions = $metrics.getAvailableMetricOptions(metricConfig.selectedMeasurement);
  const queryOptions = { ...metricConfig };

  const { selectedDimensions, selectedMetrics, selectedRollupsAggFunc, selectedVisualization } = queryOptions;

  const measurement = $metrics.measurementModelById(metricConfig.selectedMeasurement);

  if (!measurement) {
    return {};
  }

  const query =
    measurement &&
    $metrics.getFullMetricsQuery({
      metrics: selectedMetrics,
      queryOptions,
      measurement: measurement.label,
      allowMultiAggregate: false,
      ...queryConfig
    });

  return {
    availableMetricOptions: metricOptions,
    forceRunQuery: true,
    measurement,
    fullSelectedMeasurement: measurement,
    query,
    dimensions: selectedDimensions,
    metrics: selectedMetrics,
    selectedRollupsAggFunc,
    selectedVisualization
  };
}

export function getMaxKeysPerPolicy(policyApplication) {
  const maxKeysPermission = policyApplication === POLICY_APPLICATIONS.METRIC ? 'keysPerMetricPolicy' : 'keysPerPolicy';
  const permissionMax = $auth.getPermission(`alerts.limits.${maxKeysPermission}`);
  let maxKeys = permissionMax;
  if (!permissionMax && permissionMax !== 0) {
    const defaultMaxKeys = MAX_KEYS_PER_POLICY[policyApplication] || MAX_KEYS_PER_POLICY.default;
    maxKeys = defaultMaxKeys;
  }
  return maxKeys;
}

export function convertThresholdFiltersToQueryFilters(thresholdFilters = {}) {
  const conjunctions = thresholdFilters.conjunctions || [];

  const filterGroups = conjunctions.map((conjunction) => {
    const { entries = [] } = conjunction;
    const enumMatchEntries = [];
    const stringMatchEntries = [];

    // We need to convert multi match entries into sub filtergroups, so we split them up here
    entries.forEach((entry) => {
      if (entry?.values?.length > 0) {
        enumMatchEntries.push(entry);
      } else if (entry?.patterns?.length > 0) {
        stringMatchEntries.push(entry);
      }
    });

    return buildFilterGroup({
      filterGroups: enumMatchEntries.map((entry) => {
        const { dimension, not, values: enumValues } = entry;

        // This might look inverted, but let me explain:
        // If you convert "Do not match the following values: A, B, C" into filter groups,
        // that actually means: "All of the following must be true: not A, not B, not C".
        const operator = not ? '<>' : '=';
        const connector = not ? 'All' : 'Any';

        // Build subfilterGroup
        return buildFilterGroup({
          connector,
          filters: enumValues.map((value) => ({
            filterField: dimension,
            operator,
            filterValue: value
          }))
        });
      }),
      filters: stringMatchEntries.map((entry) => {
        const { dimension, patterns, not } = entry || {};
        const pattern = patterns[0];

        return {
          filterField: dimension,
          operator: not ? 'NOT ILIKE' : 'ILIKE', // This is a simple string [not] contains
          // operator: not ? '!~* ' : '~*', // if we're using regex
          filterValue: pattern
        };
      })
    });
  });

  return {
    connector: 'Any',
    filterGroups
  };
}

export function transformFormValuesToEventPolicy(wizardPolicy = {}, savedStandardPolicy = {}) {
  const { name, description, enabled, target = {}, scope = {}, device_selector } = wizardPolicy;
  const {
    name: savedName,
    id: savedPolicyId,
    activationSettings = {},
    applicationMetadata = {},
    thresholds: savedThresholds = []
  } = savedStandardPolicy;
  const thresholdSeverityToThresholdIdMap = {};
  const parsedStandardApplicationMetadata = safelyParseJSON(applicationMetadata);
  const dimensions = target?.custom?.dimensions || [];
  const appProtoFilterFieldName = `ktappprotocol__event_${isSyslogEventPolicy(dimensions) ? EVENT_POLICY_TYPES.SYSLOG : EVENT_POLICY_TYPES.SNMP_TRAP}__INT64_00`;
  const appProtoFilter = {
    filterField: appProtoFilterFieldName,
    operator: '>',
    filterValue: 0
  };

  // If this policy has already been saved, it has threshold IDs that need
  // to be preserved, which are not represented by the Native NMS policy shape.
  if (savedThresholds?.length) {
    savedThresholds.forEach(({ severity, thresholdID }) => {
      thresholdSeverityToThresholdIdMap[severity] = thresholdID;
    });
  }

  const BASELINE_SETTINGS_TEMPLATE = {
    datapointAggregationMethod: 'REL_P25',
    startOffset: 86400,
    length: 518400,
    minimalLength: 1,
    seasonality: 'hourly',
    neighbourhoodAggregationMethod: '',
    neighbourhoodRadius: 1,
    comparisonValueAggregateFunction: 'REL_P10',
    weekendAware: true,
    minimal_length_unit: 'days'
  };

  const policyFilters = (scope?.filterGroups || []).map((filterGroup) => ({
    ...filterGroup,
    connector: filterGroup.connector.toLowerCase()
  }));

  const standardPolicy = {
    name: name || savedName,
    description,
    metrics: ['fps'],
    filters: {
      connector: (scope?.filterGroups?.connector || 'all').toLowerCase(),
      filterGroups: policyFilters?.length ? policyFilters : [{ connector: 'all', filters: [appProtoFilter] }]
    },
    status: enabled ? 'active' : 'disabled',
    application: POLICY_APPLICATIONS.KEVENT,
    applicationMetadata: {
      ...parsedStandardApplicationMetadata,
      [POLICY_APPLICATIONS.KEVENT]: wizardPolicy
    },
    baselineBackfillImmediately: false,
    dimensions,
    selected_devices: { all_devices: true, ...device_selector },
    activationSettings: {
      ...activationSettings,
      mode: POLICY_MODES.TOGGLE,
      activationDelay: '0',
      clearanceDelay: '0',
      disableLegacyFlow: true
    },
    clearOverride: null,
    evaluationPeriod: '0s',
    nTopKeysEvaluated: '1001',
    nTopKeysAddedToBaseline: '0',
    minTrafficValue: 0,
    labelIds: [],
    baselineSettings: BASELINE_SETTINGS_TEMPLATE
  };

  if (savedPolicyId) {
    standardPolicy.id = savedPolicyId;
  }

  const THRESHOLD_TEMPLATE = {
    enabled: true,
    direction: 'currentToHistory',
    conditions: [
      {
        metric: standardPolicy.metrics[0],
        operator: 'greaterThanOrEquals',
        comparisonValue: 0,
        comparisonValueFactor: 1,
        type: 'static',
        direction: 'thresholdDirectionNone',
        value_select: 'metric_0',
        fallbackSettings: null,
        keySettings: null,
        ratioSettings: null
      }
    ],
    activate: {
      times: 0,
      timeWindow: 0,
      timeUnit: 'm',
      gracePeriod: 0
    },
    description: '',
    ackRequired: false,
    filters: null,
    mitigations: [],
    notificationChannels: []
  };

  // Filters conjunction entries must have either patterns or values.
  // If both are set, the API will reject them.
  const transformThresholdFilters = (filters = {}) => {
    const transformedConjunctions = (filters?.conjunctions || []).map((conjunction = {}) => ({
      ...conjunction,
      entries: (conjunction?.entries || []).map((entry = {}) => {
        if (Array.isArray(entry.patterns) && entry?.patterns?.filter(Boolean)?.length > 0) {
          delete entry.values;
        } else {
          delete entry.patterns;
        }
        return entry;
      })
    }));

    // isInclude determines whether filter matches (true) or excludes (false) traffic.
    // If it is omitted, it will be set to true by default.
    return { ...filters, conjunctions: transformedConjunctions, isInclude: true };
  };

  standardPolicy.thresholds = wizardPolicy.levels.map((level) => {
    const { severity: alertManagerSeverity, notificationChannels = [], settings = {} } = level || {};
    const {
      acknowledge_required,
      kevent: { filters }
    } = settings;
    const standardSeverity = ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY[alertManagerSeverity];
    const thresholdID = thresholdSeverityToThresholdIdMap[standardSeverity];

    const standardThreshold = {
      ...THRESHOLD_TEMPLATE,
      severity: standardSeverity,
      filters: transformThresholdFilters(filters),
      ackRequired: acknowledge_required,
      notificationChannels
    };

    if (thresholdID) {
      standardThreshold.thresholdID = thresholdID;
    }

    if (standardPolicy.id) {
      standardThreshold.policyID = standardPolicy.id;
    }

    return standardThreshold;
  });

  return standardPolicy;
}
