export const PLATFORM_TYPES = [
  {
    value: 'mit2bgp',
    label: 'Adaptive Flowspec',
    description: 'Traffic analysis mitigation platform using BGP Flowspec.'
  },
  {
    value: 'flowspec',
    label: 'Flowspec',
    description: 'Router-based mitigation protocol'
  },
  {
    value: 'rtbh',
    label: 'RTBH',
    description: 'Router-based mitigation protocol.'
  },
  {
    value: 'a10',
    label: 'A10 TPS',
    description: 'Hardware mitigation device.',
    advanced: true
  },
  {
    value: 'cfmt',
    label: 'Cloudflare Magic Transit',
    description: 'Cloud-based mitigation service.',
    advanced: true
  },
  {
    value: 'radware',
    label: 'Radware',
    description: 'Hardware mitigation device.',
    advanced: true
  }
];

export const MITIGATION_STATES = [
  'ackRequired',
  'clearing',
  'clearingFail',
  'endGrace',
  'endWait',
  'manualClearing',
  'manualClearingFail',
  'manualMitigating',
  'manualStarting',
  'manualStartingFail',
  'mitigating',
  'starting',
  'startingFail',
  'startWait',
  'archived'
];

export const STATUS_TO_STATES = {
  Active: ['mitigating', 'endGrace', 'clearing', 'starting', 'manualMitigating', 'manualClearing', 'manualStarting'],
  Waiting: ['ackRequired', 'endWait', 'startWait'],
  Failed: ['clearingFail', 'startingFail', 'manualClearingFail', 'manualStartingFail'],
  Inactive: ['archived', 'manualClear']
};

export const mitigationMethodFieldToMitigationDetailsMap = {
  sourceIpCidr: 'source',
  destIpCidr: 'destination',
  dstPorts: 'destination-port',
  icmpTypes: 'icmp-type',
  icmpCodes: 'icmp-code',
  tcpFlags: 'tcp-flags',
  packetLengths: 'packet-length',
  dscps: 'dscp',
  protocols: 'protocol',
  fragments: 'fragment'
};

export const mitigationDetailsToFilterKeysMap = {
  destIpCidr: 'inet_dst_addr',
  sourceIpCidr: 'inet_src_addr',
  dstPorts: 'l4_dst_port',
  srcPorts: 'l4_src_port',
  icmpTypes: 'ktsubtype__paloalto__INT00', // I think this it and it's a Palo Alto firewall only thing
  packetLengths: 'sampledpktsize',
  protocols: 'protocol',
  tcpFlags: 'tcp_flags', // note operator "=" = equals/is only set, "<>" = not equals, "&" = is set
  dscps: 'dscp'
  // I don't think we have these so let's skip it for now
  // icmpCodes: '',
  // fragments: '',
  // ports: '',
  // sample: ''
};

// FLOWSPEC CONSTANTS

export const FLOWSPEC_MANUAL_MITIGATION_TARGET_LABELS = {
  'source-port': 'Source Port',
  'destination-port': 'Destination Port'
};

export const FLOWSPEC_TRAFFIC_ACTIONS = ['rateLimit', 'discard', 'markDSCP', 'routeTargetRedirect', 'nextHopRedirect'];
export const FLOWSPEC_MATCH_FIELDS = [
  'destIpCidr',
  'sourceIpCidr',
  'protocols',
  'dstPorts',
  'srcPorts',
  'icmpTypes',
  'icmpCodes',
  'tcpFlags',
  'dscps',
  'packetLengths',
  'fragments'
];

// MIT2BGP Constants

export const MIT2BGP_NUMERIC_OPERATORS = [
  { value: 'FS_OP_EQ', label: 'equals' },
  { value: 'FS_OP_GT', label: 'is greater than' },
  { value: 'FS_OP_GTEQ', label: 'is greater than or equal to' },
  { value: 'FS_OP_LT', label: 'is less than' },
  { value: 'FS_OP_LTEQ', label: 'is less than or equal to' },
  { value: 'FS_OP_NEQ', label: 'does not equal' }
];

export const MIT2BGP_BINARY_OPERATORS = [
  { value: 'FS_OP_BIN_ANY', label: 'any' },
  { value: 'FS_OP_BIN_NONE', label: 'none' },
  { value: 'FS_OP_BIN_ALL', label: 'all' },
  { value: 'FS_OP_BIN_NOT_ALL', label: 'not all' }
];

export const MIT2BGP_ALGORITHM_OPTIONS = [
  { label: 'Top Source Port Numbers', value: 'srcport' },
  { label: 'Top Source CIDRs', value: 'srcip' },
  { label: 'Top Destination Port Numbers', value: 'dstport' },
  { label: 'Top Destination CIDRs', value: 'dstip' }
];

export const TCP_FLAG_OPTIONS = [
  { label: 'FIN (0)', value: '0' },
  { label: 'SYN (1)', value: '1' },
  { label: 'RST (2)', value: '2' },
  { label: 'PSH (3)', value: '3' },
  { label: 'ACK (4)', value: '4' },
  { label: 'URG (5)', value: '5' },
  { label: 'ECE (8)', value: '6' },
  { label: 'CWR (7)', value: '7' }
];

export const PROTOCOL_OPTIONS = [
  { value: '0', label: 'HOPOPT' },
  { value: '1', label: 'ICMP' },
  { value: '4', label: 'IPv4' },
  { value: '6', label: 'TCP' },
  { value: '17', label: 'UDP' },
  { value: '47', label: 'GRE' },
  { value: '50', label: 'ESP' },
  { value: '58', label: 'IPv6-ICMP' },
  {
    separator: true
  },
  // and now all the rest that aren't as common
  { value: '2', label: 'IGMP' },
  { value: '3', label: 'GGP' },
  { value: '5', label: 'ST' },
  { value: '7', label: 'CBT' },
  { value: '8', label: 'EGP' },
  { value: '9', label: 'IGP' },
  { value: '10', label: 'BBN-RCC-MON' },
  { value: '11', label: 'NVP-II' },
  { value: '12', label: 'PUP' },
  { value: '14', label: 'EMCON' },
  { value: '15', label: 'XNET' },
  { value: '16', label: 'CHAOS' },
  { value: '18', label: 'MUX' },
  { value: '19', label: 'DCN-MEAS' },
  { value: '20', label: 'HMP' },
  { value: '21', label: 'PRM' },
  { value: '22', label: 'XNS-IDP' },
  { value: '23', label: 'TRUNK-1' },
  { value: '24', label: 'TRUNK-2' },
  { value: '25', label: 'LEAF-1' },
  { value: '26', label: 'LEAF-2' },
  { value: '27', label: 'RDP' },
  { value: '28', label: 'IRTP' },
  { value: '29', label: 'ISO-TP4' },
  { value: '30', label: 'NETBLT' },
  { value: '31', label: 'MFE-NSP' },
  { value: '32', label: 'MERIT-INP' },
  { value: '33', label: 'DCCP' },
  { value: '34', label: '3PC' },
  { value: '35', label: 'IDPR' },
  { value: '36', label: 'XTP' },
  { value: '37', label: 'DDP' },
  { value: '38', label: 'IDPR' },
  { value: '39', label: 'TP++' },
  { value: '40', label: 'IL' },
  { value: '41', label: 'IPv6' },
  { value: '42', label: 'SDRP' },
  { value: '43', label: 'IPv6-Route' },
  { value: '44', label: 'IPv6-Frag' },
  { value: '45', label: 'IDRP' },
  { value: '46', label: 'RSVP' },
  { value: '48', label: 'DSR' },
  { value: '49', label: 'BNA' },
  { value: '51', label: 'AH' },
  { value: '52', label: 'I-NLSP' },
  { value: '54', label: 'NARP' },
  { value: '55', label: 'Min-IPv4' },
  { value: '56', label: 'TLSP' },
  { value: '57', label: 'SKIP' },
  { value: '59', label: 'IPv6-NoNxt' },
  { value: '60', label: 'IPv6-Opts' },
  { value: '62', label: 'CFTP' },
  { value: '64', label: 'SAT-EXPAK' },
  { value: '65', label: 'KRYPTOLAN' },
  { value: '66', label: 'RVD' },
  { value: '67', label: 'IPPC' },
  { value: '69', label: 'SAT-MON' },
  { value: '70', label: 'VISA' },
  { value: '71', label: 'IPCV' },
  { value: '72', label: 'CPNX' },
  { value: '73', label: 'CPHB' },
  { value: '74', label: 'WSN' },
  { value: '75', label: 'PVP' },
  { value: '76', label: 'BR-SAT-MON' },
  { value: '77', label: 'SUN-ND' },
  { value: '78', label: 'WB-MON' },
  { value: '79', label: 'WB-EXPAK' },
  { value: '80', label: 'ISO-IP' },
  { value: '81', label: 'VMTP' },
  { value: '82', label: 'SECURE-VMTP' },
  { value: '83', label: 'VINES' },
  { value: '84', label: 'IPTM' },
  { value: '85', label: 'NSFNET-IGP' },
  { value: '86', label: 'DGP' },
  { value: '87', label: 'TCF' },
  { value: '88', label: 'EIGRP' },
  { value: '89', label: 'OSPFIGP' },
  { value: '90', label: 'Sprite-RPC' },
  { value: '91', label: 'LARP' },
  { value: '92', label: 'MTP' },
  { value: '93', label: 'AX.25' },
  { value: '94', label: 'IPIP' },
  { value: '96', label: 'SCC-SP' },
  { value: '97', label: 'ETHERIP' },
  { value: '98', label: 'ENCAP' },
  { value: '100', label: 'GMTP' },
  { value: '101', label: 'IFMP' },
  { value: '102', label: 'PNNI' },
  { value: '103', label: 'PIM' },
  { value: '104', label: 'ARIS' },
  { value: '105', label: 'SCPS' },
  { value: '106', label: 'QNX' },
  { value: '107', label: 'A/N' },
  { value: '108', label: 'IPComp' },
  { value: '109', label: 'SNP' },
  { value: '110', label: 'Compaq-Peer' },
  { value: '111', label: 'IPX-in-IP' },
  { value: '112', label: 'VRRP' },
  { value: '113', label: 'PGM' },
  { value: '115', label: 'L2TP' },
  { value: '116', label: 'DDX' },
  { value: '117', label: 'IATP' },
  { value: '118', label: 'STP' },
  { value: '119', label: 'SRP' },
  { value: '120', label: 'UTI' },
  { value: '121', label: 'SMP' },
  { value: '123', label: 'PTP' },
  { value: '124', label: 'ISIS over IPv4' },
  { value: '125', label: 'FIRE' },
  { value: '126', label: 'CRTP' },
  { value: '127', label: 'CRUDP' },
  { value: '128', label: 'SSCOPMCE' },
  { value: '129', label: 'IPLT' },
  { value: '130', label: 'SPS' },
  { value: '131', label: 'FC' },
  { value: '132', label: 'SCTP' },
  { value: '133', label: 'FC' },
  { value: '134', label: 'RSVP-E2E-IGNORE' },
  { value: '135', label: 'Mobility Header' },
  { value: '136', label: 'UDPLite' },
  { value: '137', label: 'MPLS-in-IP' },
  { value: '138', label: 'manet' },
  { value: '139', label: 'HIP' },
  { value: '140', label: 'Shim6' },
  { value: '141', label: 'WESP' },
  { value: '142', label: 'ROHC' },
  { value: '143', label: 'Ethernet' },
  { value: '144', label: 'AGGFRAG' },
  { value: '145', label: 'NSH' },
  { value: '146', label: 'Homa' }
];

export const MIT2BGP_MATCH_TYPE_HASH = {
  FS_MATCH_TYPE_ICMP_TYPE: 'icmp_type',
  FS_MATCH_TYPE_ICMP_CODE: 'icmp_code',
  FS_MATCH_TYPE_PKT_LEN: 'packet_length',
  FS_MATCH_TYPE_DSCP: 'dscp',
  FS_MATCH_TYPE_FRAG: 'fragment'
};

export const MIT2BGP_TEMPLATE_MATCHES = [
  {
    value: 'FS_MATCH_TYPE_DST4_PREFIX',
    label: 'Destination IP/CIDR - IPv4',
    canInfer: true
  },
  {
    value: 'FS_MATCH_TYPE_SRC4_PREFIX',
    label: 'Source IP/CIDR - IPv4',
    canInfer: true
  },
  {
    value: 'FS_MATCH_TYPE_DST6_PREFIX',
    label: 'Destination IP/CIDR - IPv6',
    canInfer: true
  },
  {
    value: 'FS_MATCH_TYPE_SRC6_PREFIX',
    label: 'Source IP/CIDR - IPv6',
    canInfer: true
  },
  { value: 'FS_MATCH_TYPE_PROTO', label: 'Protocols', operators: MIT2BGP_NUMERIC_OPERATORS, canInfer: true },
  { value: 'FS_MATCH_TYPE_PORT', label: 'Source or Destination Ports', operators: MIT2BGP_NUMERIC_OPERATORS },
  { value: 'FS_MATCH_TYPE_SRC_PORT', label: 'Source Ports', operators: MIT2BGP_NUMERIC_OPERATORS, canInfer: true },
  { value: 'FS_MATCH_TYPE_DST_PORT', label: 'Destination Ports', operators: MIT2BGP_NUMERIC_OPERATORS, canInfer: true },
  { value: 'FS_MATCH_TYPE_ICMP_TYPE', label: 'ICMP Types', operators: MIT2BGP_NUMERIC_OPERATORS },
  { value: 'FS_MATCH_TYPE_ICMP_CODE', label: 'ICMP Codes', operators: MIT2BGP_NUMERIC_OPERATORS },
  { value: 'FS_MATCH_TYPE_TCP_FLAGS', label: 'TCP Flags', operators: MIT2BGP_BINARY_OPERATORS, canInfer: true },
  {
    value: 'FS_MATCH_TYPE_PKT_LEN',
    label: 'Packet Lengths',
    operators: MIT2BGP_NUMERIC_OPERATORS,
    placeholder: 'Packet length in bytes'
  },
  { value: 'FS_MATCH_TYPE_DSCP', label: 'DSCP Flags', operators: MIT2BGP_NUMERIC_OPERATORS, canInfer: true },
  { value: 'FS_MATCH_TYPE_FRAG', label: 'Fragments', operators: MIT2BGP_BINARY_OPERATORS },
  { value: 'FS_MATCH_TYPE_FLOW_LABEL', label: 'Flow Label - IPv6', operators: MIT2BGP_NUMERIC_OPERATORS }
];

export const MIT2BGP_TEMPLATE_ACTIONS = [
  {
    value: 'FS_ACTION_TYPE_ACCEPT',
    label: 'Accept',
    excludes: ['FS_ACTION_TYPE_DISCARD', 'FS_ACTION_TYPE_RATE_BYTES'],
    boolean: true
  },
  {
    value: 'FS_ACTION_TYPE_DISCARD',
    label: 'Discard',
    excludes: ['FS_ACTION_TYPE_ACCEPT', 'FS_ACTION_TYPE_RATE_BYTES'],
    boolean: true
  },
  {
    value: 'FS_ACTION_TYPE_RATE_BYTES',
    label: 'Rate Limit',
    placeholder: 'Rate limit in bytes/sec',
    excludes: ['FS_ACTION_TYPE_ACCEPT', 'FS_ACTION_TYPE_DISCARD']
  },
  { value: 'FS_ACTION_TYPE_ACTION:SAMPLE', label: 'Sample', boolean: true },
  { value: 'FS_ACTION_TYPE_ACTION:TERMINAL', label: 'Terminal', boolean: true },
  { value: 'FS_ACTION_TYPE_MARK', label: 'Mark DSCP' },
  {
    value: 'FS_ACTION_TYPE_REDIRECT',
    label: 'Route-Target Redirect',
    placeholder: 'Ex: xxxxx:yyyyy',
    excludes: [
      'FS_ACTION_TYPE_REDIRECT_IP4',
      'FS_ACTION_TYPE_COPY_IP4',
      'FS_ACTION_TYPE_REDIRECT_IP6',
      'FS_ACTION_TYPE_COPY_IP6'
    ]
  },
  {
    value: 'FS_ACTION_TYPE_REDIRECT_IP4',
    label: 'Redirect to IPv4',
    placeholder: 'Enter IPv4 Address (No CIDRs)',
    excludes: ['FS_ACTION_TYPE_REDIRECT', 'FS_ACTION_TYPE_COPY_IP4']
  },
  {
    value: 'FS_ACTION_TYPE_COPY_IP4',
    label: 'Copy to IPv4',
    placeholder: 'Enter IPv4 Address (No CIDRs)',
    excludes: ['FS_ACTION_TYPE_REDIRECT', 'FS_ACTION_TYPE_REDIRECT_IP4']
  },
  {
    value: 'FS_ACTION_TYPE_REDIRECT_IP6',
    label: 'Redirect to IPv6',
    placeholder: 'Enter IPv6 Address (No CIDRs)',
    excludes: ['FS_ACTION_TYPE_REDIRECT', 'FS_ACTION_TYPE_COPY_IP6']
  },
  {
    value: 'FS_ACTION_TYPE_COPY_IP6',
    label: 'Copy to IPv6',
    placeholder: 'Enter IPv6 Address (No CIDRs)',
    excludes: ['FS_ACTION_TYPE_REDIRECT', 'FS_ACTION_TYPE_REDIRECT_IP6']
  },
  { value: 'FS_ACTION_TYPE_COMMUNITY', label: 'Community', placeholder: 'Ex: 123:456' },
  {
    value: 'FS_ACTION_TYPE_EXT_COMM',
    label: 'Extended Community',
    placeholder: 'Ex: target:123:456 or origin:123:456'
  },
  { value: 'FS_ACTION_TYPE_LARGE_COMM', label: 'Large Community', placeholder: 'Ex: 123:456:789' }
];

export const MIT2BGP_TRAFFIC_METRICS = [
  { value: 'METRIC_BPS', label: 'Bits per second' },
  { value: 'METRIC_PPS', label: 'Packets per second' },
  { value: 'METRIC_FPS', label: 'Flows per second' }
];

export const MIT2BGP_GLOBAL_INFER_OPTIONS = [
  {
    value: 'FS_INFER_ENABLED',
    label: 'Enabled',
    description: 'Overwrites your value with matching alert dimension value.'
  },
  {
    value: 'FS_INFER_DISABLED',
    label: 'Disabled',
    description: 'Does not attempt to overwrite your value with alert dimension value.'
  },
  { value: 'FS_INFER_MERGE', label: 'Merge', description: 'Adds your value to matching alert dimension value.' }
];

export const MIT2BGP_INFER_OPTIONS = [
  { value: 'FS_INFER_UNSPECIFIED', label: 'Use Default', description: 'Uses "Default Infer from Alarm" setting.' },
  ...MIT2BGP_GLOBAL_INFER_OPTIONS
];

export const OPERATOR_OPTIONS = [
  {
    label: 'equals',
    value: 'equals'
  },
  {
    label: 'does not equal',
    value: 'notEquals'
  },
  {
    label: 'is greater than',
    value: 'greaterThan'
  },
  {
    label: 'is greater than or equal to',
    value: 'greaterThanOrEquals'
  },
  {
    label: 'is less than',
    value: 'lessThan'
  },
  {
    label: 'is less than or equal to',
    value: 'lessThanOrEquals'
  }
];
