import BaseModel from 'core/model/Model';
import { api } from 'core/util/index';

export default class AuthProfile extends BaseModel {
  get urlRoot() {
    return `/api/ui/authProfile/config${this.get('isTenant') ? '/tenant' : ''}`;
  }

  get omitDuringSerialize() {
    return ['id', 'companyName', 'isTenant', 'meta_ssoEnforcement', 'userLevelConfig', 'roleSetConfig'];
  }

  serialize(data = {}) {
    const { meta_ssoEnforcement } = data;
    const meta_ssoEnabled = meta_ssoEnforcement === 'enabled' || meta_ssoEnforcement === 'required' || false;
    const meta_ssoRequired = meta_ssoEnforcement === 'required' || false;

    return super.serialize({ ...data, meta_ssoEnabled, meta_ssoRequired });
  }

  deserialize(data = {}) {
    const transformedData = super.deserialize(data);
    const { meta_ssoEnabled, meta_ssoRequired } = transformedData;
    if (meta_ssoRequired) {
      transformedData.meta_ssoEnforcement = 'required';
    } else if (meta_ssoEnabled) {
      transformedData.meta_ssoEnforcement = 'enabled';
    } else {
      transformedData.meta_ssoEnforcement = 'disabled';
    }

    return transformedData;
  }

  fetchAttributes() {
    return api.get('/api/ui/rbac/sso-attributes').then((data) => {
      const { userLevelConfig } = data;
      const validOptions = ['LOCAL', 'MEMBER', 'DENIED'];
      // if value is anything other than valid options, set it to local
      if (userLevelConfig && !validOptions.includes(userLevelConfig.default)) {
        data.userLevelConfig.default = 'LOCAL';
      }

      this.set({ ...data });
    });
  }

  saveAttributes(attributes) {
    const { userLevelConfig, roleSetConfig } = attributes;
    return api.post('/api/ui/rbac/sso-attributes', { body: { userLevelConfig, roleSetConfig } });
  }

  fetch(options) {
    return super.fetch(options).then(() => this.fetchAttributes());
  }

  async save(attributes = {}, options = {}) {
    return super.save(attributes, options).then(() => this.saveAttributes(attributes));
  }
}
