import Collection from 'core/model/Collection';
import RbacPermModel from './RbacPermModel';

class RbacPermCollection extends Collection {
  get url() {
    return '/api/ui/rbac/permissions';
  }

  get model() {
    return RbacPermModel;
  }

  deserialize(data) {
    const normalizedData = [];

    for (let i = 0; i < data.groups.length; i++) {
      const { name, permissions } = data.groups[i];

      for (let p = 0; p < permissions.length; p++) {
        const { permission, display, labelFilter } = permissions[p];
        normalizedData.push({ group: name, permission, display, labelFilter });
      }
    }
    return normalizedData;
  }
}

export default RbacPermCollection;
