import { action, computed, observable } from 'mobx';
import { escapeRegExp, get } from 'lodash';
import { addCustomPropertiesToEntity, getCustomProperties, uriToObject } from 'shared/util/map';
import { ENTITY_TYPES, MAX_ALLOWED_SELECTED_SUBSCRIPTIONS } from 'shared/hybrid/constants';
import { showInfoToast } from 'core/components/toast';
import { getEntityType, getEntityTypeById } from 'app/views/hybrid/utils/map';

import AzureMapModel from './AzureMapModel';
import CloudMapCollection from './CloudMapCollection';

const {
  ROUTER,
  LOCAL_NETWORK_GATEWAY,
  LOCATION,
  VNET,
  VIRTUAL_WAN,
  VPN_LINK_CONNECTION,
  VNET_GATEWAY_CONNECTION,
  EXPRESS_ROUTE_CIRCUIT,
  EXPRESS_ROUTE_CONNECTION,
  EXPRESS_ROUTE_GATEWAY,
  PUBLIC_IP_ADDRESS,
  VNET_GATEWAY,
  VIRTUAL_HUB,
  VPN_SITE,
  HUB_VIRTUAL_NETWORK_CONNECTION,
  VPN_CONNECTIONS,
  VPN_GATEWAY
} = ENTITY_TYPES.get('azure');

export const ENTITY_KEY_APPLICATION_GATEWAYS = 'applicationGateways';
export const ENTITY_KEY_AZURE_FIREWALLS = 'azureFirewalls';
export const ENTITY_KEY_EXPRESS_ROUTE_CIRCUITS = 'expressRouteCircuits';
export const ENTITY_KEY_EXPRESS_ROUTE_CONNECTIONS = 'expressRouteConnections';
export const ENTITY_KEY_EXPRESS_ROUTE_GATEWAYS = 'expressRouteGateways';
export const ENTITY_KEY_HUB_VNET_CONNECTIONS = 'hubVirtualNetworkConnections';
export const ENTITY_KEY_LOAD_BALANCERS = 'loadBalancers';
export const ENTITY_KEY_LOCAL_NETWORK_GATEWAYS = 'localNetworkGateways';
export const ENTITY_KEY_LOCATIONS = 'locations';
export const ENTITY_KEY_NAT_GATEWAYS = 'natGateways';
export const ENTITY_KEY_ENI = 'networkInterfaces';
export const ENTITY_KEY_NETWORK_SECURITY_GROUPS = 'networkSecurityGroups';
export const ENTITY_KEY_P2SVPN_GATEWAYS = 'p2SVpnGateways';
export const ENTITY_KEY_P2S_CONNECTION_CONFIGS = 'p2sConnectionConfigurations';
export const ENTITY_KEY_PUBLIC_IP_ADDRESSES = 'publicIPAddresses';
export const ENTITY_KEY_ROUTE_TABLES = 'routeTables';
export const ENTITY_KEY_ROUTERS = 'routers';
export const ENTITY_KEY_SUBNETS = 'subnets';
export const ENTITY_KEY_VIRTUAL_HUBS = 'virtualHubs';
export const ENTITY_KEY_VIRTUAL_WANS = 'virtualWans';
export const ENTITY_KEY_VMS = 'vms';
export const ENTITY_KEY_VNET_GATEWAY_CONNECTIONS = 'vnetGatewayConnections';
export const ENTITY_KEY_VNET_GATEWAYS = 'vnetGateways';
export const ENTITY_KEY_VNET_PEERINGS = 'vnetPeerings';
export const ENTITY_KEY_VNETS = 'vnets';
export const ENTITY_KEY_VPN_CONNECTIONS = 'vpnConnections';
export const ENTITY_KEY_VPN_GATEWAYS = 'vpnGateways';
export const ENTITY_KEY_VPN_LINK_CONNECTIONS = 'vpnLinkConnections';
export const ENTITY_KEY_VPN_SITES = 'vpnSites';

class AzureCloudMapCollection extends CloudMapCollection {
  collectionManagedEntityTypes = [VNET, VIRTUAL_HUB];

  searchableIdPaths = ['device_id', 'name', 'device_name', 'location'];

  searchableCIDRPaths = [
    'connectedInterfaces', // routers
    'properties.peerings', // expressRouteCircuits
    'properties.ipAddress', // subnets
    'properties.virtualHubs', // virtualWans
    'properties.bgpSettings', // vnetGateways
    'properties.addressPrefix', // subnets
    'properties.publicIpAddresses', // natGateways
    'properties.gatewayIpAddress', // localNetworkGateways
    'properties.virtualRouterIps', // localNetworkGateways
    'properties.localNetworkGateway2', // vnetGatewayConnections
    'properties.virtualNetworkGateway1', // vnetGatewayConnections
    'properties.expressRouteCircuitPeering' // expressRouteConnections
  ];

  @observable.ref
  initialTopology = { Hierarchy: { locations: [] }, Entities: {}, Links: [], Summary: {}, traffic: [] };

  @observable
  /** [{type, value}] */
  highLightedNodes = [];

  @observable
  isLoading = false;

  get model() {
    return AzureMapModel;
  }

  get url() {
    return '/api/ui/topology/cloud-hierarchy/azure';
  }

  get fetchMethod() {
    return 'post';
  }

  get selectedSubscriptions() {
    return this.parent?.settingsModel?.get('azureSubscriptionIds') ?? [];
  }

  async fetch(options) {
    let selectedAzureSubscriptionIds = this.selectedSubscriptions;

    if (!selectedAzureSubscriptionIds.length && options?.azureSubscriptionIds?.length) {
      selectedAzureSubscriptionIds = options.azureSubscriptionIds;
    }

    this.isLoading = true;
    return super
      .fetch({
        data: {
          subscriptionIds: selectedAzureSubscriptionIds
        },
        ...options
      })
      .then((res) => {
        if (selectedAzureSubscriptionIds.length > MAX_ALLOWED_SELECTED_SUBSCRIPTIONS) {
          showInfoToast(
            `Current subscription limit is ${MAX_ALLOWED_SELECTED_SUBSCRIPTIONS}. Topology result might not be complete.`,
            {
              title: 'Azure topologoy',
              timeout: 4000
            }
          );
        }

        return res;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /*
    A location is empty and should not be rendered in the map when:

    - there are no vnets
    - @TODO other assets connected to the location are empty
  */
  isLocationHierarchyEmpty(location) {
    return !location.vnets?.length && !location.virtualHubs?.length;
  }

  /*
    Returns a unique list of account ids
    In the azure world, we're going to count subscription and tenant ids as being account ids
  */
  @computed
  get accountIds() {
    return this.unfiltered.reduce((accountIds, model) => {
      const { subscriptionId, tenantId } = getCustomProperties(model.get());

      if (subscriptionId && !accountIds.includes(subscriptionId)) {
        accountIds.push(subscriptionId);
      }

      if (tenantId && !accountIds.includes(tenantId)) {
        accountIds.push(tenantId);
      }

      return accountIds;
    }, []);
  }

  @computed
  get filterStateGroups() {
    const query = this.filterState || '';

    return query
      .split(',')
      .map((term) => term.trim())
      .reduce(
        (acc, term) => {
          if (term === '') {
            return acc;
          }

          if (this.isValidIP(term)) {
            acc.cidrs.push(term);
            return acc;
          }

          if (this.accountIds.includes(term)) {
            acc.accountIds.push(term);
          } else if (term.includes('=')) {
            acc.tags.push(term);
          } else {
            acc.ids.push(term);
          }

          return acc;
        },
        {
          accountIds: [],
          ids: [],
          cidrs: [],
          tags: []
        }
      );
  }

  // return a list of models by entity type such as 'vnets'
  // @TODO currently overridden in azure because we favor stashing this in custom properties rather than polluting the azure entity
  getModelsByEntityType(entityType) {
    return this.models.filter((model) => getEntityType(model) === entityType).map((model) => model.get());
  }

  @computed
  get topology() {
    const { Hierarchy } = this.initialTopology;
    const platformHierarchy = {};

    platformHierarchy.locations = Hierarchy.locations
      .reduce(
        (locations, location) => locations.concat(this.hydrateHierarchy({ entity: location, entityType: LOCATION })),
        []
      )
      .filter((location) => !this.isLocationHierarchyEmpty(location));

    return {
      ...this.initialTopology,
      Hierarchy: platformHierarchy,
      [ROUTER]: this.getModelsByEntityType(ROUTER),
      [VIRTUAL_WAN]: this.getModelsByEntityType(VIRTUAL_WAN),
      [VNET_GATEWAY_CONNECTION]: this.getModelsByEntityType(VNET_GATEWAY_CONNECTION),
      [EXPRESS_ROUTE_CONNECTION]: this.getModelsByEntityType(EXPRESS_ROUTE_CONNECTION),
      [VPN_LINK_CONNECTION]: this.getModelsByEntityType(VPN_LINK_CONNECTION),
      [EXPRESS_ROUTE_CIRCUIT]: this.getModelsByEntityType(EXPRESS_ROUTE_CIRCUIT),
      [LOCAL_NETWORK_GATEWAY]: this.getModelsByEntityType(LOCAL_NETWORK_GATEWAY),
      [VPN_SITE]: this.getModelsByEntityType(VPN_SITE),
      [VIRTUAL_HUB]: this.getModelsByEntityType(VIRTUAL_HUB)
    };
  }

  filter(query) {
    this.filterState = query || '';
    const { filterStateGroups } = this;
    const discreteFilters = Object.keys(filterStateGroups).reduce((acc, groupName) => {
      const values = filterStateGroups[groupName];

      if (values.length > 0) {
        if (groupName === 'accountIds') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) => {
                const { subscriptionId, tenantId } = getCustomProperties(model.get());
                return [subscriptionId, tenantId].includes(value);
              })
          });
        }

        if (groupName === 'ids') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) =>
                model.searchableData.ids.find((id) => new RegExp(escapeRegExp(value), 'i').test(id))
              )
          });
        }

        if (groupName === 'cidrs') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) => model.searchableData.cidrs.find((cidr) => this.isInSubnet(value, cidr)))
          });
        }

        if (groupName === 'tags') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) =>
                model.searchTags.find((tag) => {
                  const [modelTagKey, modelTagValue] = tag;
                  const [filterTagKey, filterTagValue] = value.split('=');

                  // find a complete key match and a partial value match
                  return (
                    modelTagKey === filterTagKey && new RegExp(escapeRegExp(filterTagValue), 'i').test(modelTagValue)
                  );
                })
              )
          });
        }
      }

      return acc;
    }, []);

    this.models.replace(this.get());

    if (discreteFilters.length > 0) {
      discreteFilters.forEach((filter) => {
        this.models.replace(this.models.filter((model) => filter.fn(model)));
      });
    }

    this.sort();

    return this.models;
  }

  /*
    Returns a complete list of all Entities in the reference map for the given type 'entityType', decorating it with:

    - an optionally overridden id using the 'entityId' arg
    - an 'entityType' which directly relates the model to an entity type

    @TODO overridden here to place the entity type in custom properties and avoid polluting the azure entity
  */
  getActiveEntities({ entityType, entityId = 'id', allowUnlinked = false }) {
    const { Entities } = this.initialTopology;
    const entityTypeList = Object.values(Entities[entityType] || {});
    const entities = entityTypeList.map((node) => {
      const entityWithNewId = Object.assign({}, node, { id: node[entityId] });

      return addCustomPropertiesToEntity({ entity: entityWithNewId, customProperties: { entityType } });
    });

    if (allowUnlinked) {
      return entities;
    }

    return entities.filter(this.filterToLinked(entityType));
  }

  getSearchableCIDRList(data = {}) {
    const { connectedInterfaces, properties = {} } = data;
    const cidrData = connectedInterfaces ? { connectedInterfaces } : properties;

    return Object.keys(cidrData).reduce((cidrs, itemType) => {
      const itemData = cidrData[itemType];

      if (itemType === 'connectedInterfaces') {
        // routers
        return itemData?.reduce(
          (acc, connectedInterface) => [
            ...acc,
            connectedInterface?.interface_ip,
            connectedInterface?.expressRouteCircuit?.peering?.['Peer Prefix']
          ],
          cidrs
        );
      }

      if (
        itemType === 'addressPrefix' ||
        itemType === 'gatewayIpAddress' ||
        itemType === 'ipAddress' ||
        itemType === 'virtualRouterIps'
      ) {
        // subnets, localNetworkGateways
        return cidrs.concat(itemData);
      }

      if (itemType === 'peerings') {
        // expressRouteCircuits
        return itemData?.reduce(
          (acc, peering) =>
            acc.concat(peering?.properties?.primaryPeerAddressPrefix, peering?.properties?.secondaryPeerAddressPrefix),
          cidrs
        );
      }

      if (itemType === 'expressRouteCircuitPeering') {
        // expressRouteConnections
        // get the express route circuit entity id by chopping off the peering portion of the id
        const expressRouteCircuitEntityId = itemData?.id?.replace(/\/peering.*$/, '');
        // get the full express route circuit entity
        const expressRouteCircuit = this.getEntity({
          entityId: expressRouteCircuitEntityId,
          entityType: EXPRESS_ROUTE_CIRCUIT
        });

        // get the searchable cidrs from the express route circuit
        return cidrs.concat(this.getSearchableDataFromEntity({ entity: expressRouteCircuit, type: 'cidr' }));
      }

      if (itemType === 'publicIpAddresses') {
        // natGateways
        return itemData?.reduce((acc, item) => {
          const publicIpAddressEntityId = item?.id;
          const publicIpAddress = this.getEntity({
            entityId: publicIpAddressEntityId,
            entityType: PUBLIC_IP_ADDRESS
          });

          // get the searchable cidrs from the express route circuit
          return acc.concat(this.getSearchableDataFromEntity({ entity: publicIpAddress, type: 'cidr' }));
        }, cidrs);
      }

      if (itemType === 'localNetworkGateway2') {
        // vnetGatewayConnections
        const localNetworkGatewayEntityId = itemData?.id;
        const localNetworkGateway = this.getEntity({
          entityId: localNetworkGatewayEntityId,
          entityType: LOCAL_NETWORK_GATEWAY
        });

        return cidrs.concat(this.getSearchableDataFromEntity({ entity: localNetworkGateway, type: 'cidr' }));
      }

      if (itemType === 'virtualNetworkGateway1') {
        // vnetGatewayConnections
        const vnetGatewayEntityId = itemData?.id;
        const vnetGateway = this.getEntity({
          entityId: vnetGatewayEntityId,
          entityType: VNET_GATEWAY
        });

        return cidrs.concat(this.getSearchableDataFromEntity({ entity: vnetGateway, type: 'cidr' }));
      }

      if (itemType === 'bgpSettings') {
        // vnetGateways
        return (itemData?.bgpPeeringAddresses || []).reduce(
          (acc, peeringAddress) => acc.concat(peeringAddress?.defaultBgpIpAddresses, peeringAddress?.tunnelIpAddresses),
          cidrs
        );
      }

      if (itemType === 'addressSpace' || itemType === 'remoteAddressSpace') {
        // vnetPeerings
        return cidrs.concat(itemData?.addressPrefixes);
      }

      if (itemType === 'routeServiceVips') {
        // vnetPeerings
        return cidrs.concat(Object.values(itemData || {}));
      }

      if (itemType === 'virtualHubs') {
        // virtualWans
        return (itemData || []).reduce((acc, vHub) => {
          const virtualHubEntityId = vHub?.id;
          const virtualHub = this.getEntity({ entityId: virtualHubEntityId, entityType: VIRTUAL_HUB });

          return acc.concat(this.getSearchableDataFromEntity({ entity: virtualHub, type: 'cidr' }));
        }, cidrs);
      }

      return cidrs;
    }, []);
  }

  deserialize(response) {
    // capture the initial topology response
    this.initialTopology = response;

    // get a comprehensive list of models for the collection
    const models = [
      { entityType: VNET },
      { entityType: ROUTER },
      { entityType: VIRTUAL_WAN },
      { entityType: VPN_LINK_CONNECTION },
      { entityType: LOCAL_NETWORK_GATEWAY },
      { entityType: EXPRESS_ROUTE_CIRCUIT },
      { entityType: EXPRESS_ROUTE_CONNECTION },
      { entityType: VNET_GATEWAY_CONNECTION },
      { entityType: VPN_SITE },
      { entityType: VIRTUAL_HUB }
    ].reduce((acc, entityConfig) => acc.concat(this.getActiveEntities({ ...entityConfig, allowUnlinked: true })), []);

    const modelsWithSearchableData = this.addSearchableDataToModels({
      entities: response.Hierarchy.locations,
      models
    });

    return modelsWithSearchableData;
  }

  getLocationEntities(location, entityType) {
    return this.getActiveEntities({ entityType, allowUnlinked: true }).filter((entity) => entity.location === location);
  }

  /**
   * will locate parent entity or null
   * example:
   * /subscriptions/c54c73fd-f778-4aa4-84e1-79d89e5a1f71/resourceGroups/RG-TIAMUT/providers/Microsoft.Network/expressRouteCircuits/Express-Route-02-Megaport/peerings/AzurePrivatePeering
   * will have peering under
   * /subscriptions/c54c73fd-f778-4aa4-84e1-79d89e5a1f71/resourceGroups/RG-TIAMUT/providers/Microsoft.Network/expressRouteCircuits/Express-Route-02-Megaport
   */
  findParentEntityByIdUri(id) {
    /** if we cannot find this entity in entities, its possible it will exist in parent entity */
    const parentIdArr = id.split('/').slice(0, -2);

    if (parentIdArr.length > 3) {
      const parentId = parentIdArr.join('/');
      const parentType = parentIdArr[parentIdArr.length - 2];
      return this.findEntityByIdUri(parentId, parentType);
    }

    return null;
  }

  // will locate entity from azure topology by id and entity type.
  findEntityByIdUri(id, entityType) {
    const parsedUriData = uriToObject(id);

    const resourceTypeKey = Object.keys(parsedUriData).find(
      (key) =>
        entityType.toLowerCase().includes(key.toLocaleLowerCase()) ||
        key.toLocaleLowerCase().includes(entityType.toLowerCase())
    );

    if (!resourceTypeKey) {
      console.warn(`No resource type found in property id(uri) - searching ${entityType}`);
      return null;
    }

    // keys in entities are plural, when in ID they are single (example: routeTables vs routeTable)
    const entityTypeKey = Object.keys(this.Entities).find((key) =>
      key.toLowerCase().startsWith(resourceTypeKey.toLocaleLowerCase())
    );

    if (!entityTypeKey) {
      console.warn(`No resource type found under Entities in response object - searching ${resourceTypeKey}`);
      return null;
    }

    for (const entityData of Object.values(this.Entities[entityTypeKey])) {
      if (entityData.id.toLowerCase() === id.toLowerCase()) {
        return entityData;
      }
    }

    /** if we cannot find this entity in entities, its possible it will exist in parent entity */
    const parentEntity = this.findParentEntityByIdUri(id);
    const currentIdType = id?.split('/')?.slice(0, -1)?.pop();
    if (parentEntity && currentIdType) {
      const parentEntityProperty = this.getEntityProperty(parentEntity, currentIdType);

      // convert to array
      const parentEntityPropertyArr =
        !Array.isArray(parentEntityProperty) && parentEntityProperty ? [parentEntityProperty] : parentEntityProperty;

      return parentEntityPropertyArr?.find((entity) => entity.id === id);
    }

    return null;
  }

  extractDestinationFromExpressRouteConnection = (expressRouteConnection) => {
    const expressRouteCirtuitPeering = this.getEntityProperty(expressRouteConnection, 'expressRouteCircuitPeering');

    const expressRouteCircuit = this.findParentEntityByIdUri(expressRouteCirtuitPeering.id);
    const connectedRouter = Object.values(this.Entities[ROUTER]).find((router) =>
      router?.connectedInterfaces?.some(
        (connectedInterface) => connectedInterface?.expressRouteCircuit?.id === expressRouteCircuit?.id
      )
    );

    const expressRouteGateway = this.findParentEntityByIdUri(expressRouteConnection?.id ?? '');

    const path = [
      { id: expressRouteGateway?.id, type: EXPRESS_ROUTE_GATEWAY },
      { id: expressRouteConnection?.id, type: EXPRESS_ROUTE_CONNECTION },
      { id: expressRouteCircuit?.id, type: EXPRESS_ROUTE_CIRCUIT },
      { id: connectedRouter?.id, type: ROUTER }
    ];

    return {
      path,
      state: expressRouteCirtuitPeering?.properties?.state === 'Enabled',
      destination: [...(connectedRouter?.connectedInterfaces ?? [])]?.pop()?.interface_ip,
      destinationResourceFound: !!connectedRouter,
      routeTarget: connectedRouter,
      routeTargetType: ROUTER
    };
  };

  extractDestinationFromVpnConnection = (vpnConnection) => {
    const remoteVpnSite = this.getEntityProperty(vpnConnection, 'remoteVpnSite');
    const vpnLinkConnections = this.getEntityProperty(vpnConnection, 'vpnLinkConnections');

    const vpnLinkConnection = vpnLinkConnections.find(
      (linkConnection) => linkConnection?.properties?.connectionStatus === 'Connected'
    );

    const vpnGateway = this.findParentEntityByIdUri(vpnConnection?.id ?? '');

    const path = [
      { id: vpnGateway?.id, type: VPN_GATEWAY },
      { id: vpnLinkConnection?.id, type: VPN_LINK_CONNECTION },
      { id: remoteVpnSite?.id, type: VPN_SITE }
    ];

    return {
      path,
      state: !!vpnLinkConnection,
      destination: (remoteVpnSite?.properties?.addressSpace?.addressPrefixes ?? []).join(','),
      destinationResourceFound: !!remoteVpnSite?.properties,
      routeTarget: remoteVpnSite,
      routeTargetType: VPN_SITE,
      nextHopGateway: vpnGateway,
      nextHopGatewayType: VPN_GATEWAY
    };
  };

  extractDestinationFromVhubVirtualNetworkConnection(hubVirtualNetworkConnection) {
    const destinationVnet = this.getEntityProperty(hubVirtualNetworkConnection, 'remoteVirtualNetwork', VNET);
    const destinationVnetProps = destinationVnet?.properties;

    const path = [
      { id: hubVirtualNetworkConnection?.id, type: HUB_VIRTUAL_NETWORK_CONNECTION },
      { id: destinationVnet?.id, type: VNET }
    ];

    return {
      state: hubVirtualNetworkConnection?.properties?.connectivityStatus === 'Connected',
      destination: (destinationVnetProps?.addressSpace?.addressPrefixes ?? []).join(','),
      destinationResourceFound: !!destinationVnetProps,
      routeTarget: destinationVnet,
      routeTargetType: VNET,
      propagated: false,
      path
    };
  }

  getVHubRoutes(entity) {
    const vhubRoutes = Object.values(entity.hubRouteTables).map((hubRouteTable) => {
      const existingRouteIds = [];
      const tableRoutes = [];

      const associatedConnections = this.getEntityProperty(hubRouteTable, 'associatedConnections');
      const propagatingConnections = this.getEntityProperty(hubRouteTable, 'propagatingConnections');
      const routes = this.getEntityProperty(hubRouteTable, 'routes');
      const connections = [
        ...associatedConnections,
        ...propagatingConnections,
        ...routes.map((route) => route.nextHop)
      ];

      connections.forEach((connectionId) => {
        const connectionEntityType = getEntityTypeById(connectionId);
        if (!this.Entities[connectionEntityType]) {
          console.error(`unknown connection ${connectionEntityType}`);
          return;
        }

        const connection = this.Entities[connectionEntityType][connectionId];

        if (!connection) {
          return;
        }

        let destination;
        switch (connectionEntityType) {
          case EXPRESS_ROUTE_CONNECTION:
            destination = this.extractDestinationFromExpressRouteConnection(connection);
            break;
          case VPN_CONNECTIONS:
            destination = this.extractDestinationFromVpnConnection(connection);
            break;
          case HUB_VIRTUAL_NETWORK_CONNECTION:
            destination = this.extractDestinationFromVhubVirtualNetworkConnection(connection);
            break;
          default:
            console.error(`unknown connection ${connectionEntityType}`);
        }

        const nextHopId = destination?.nextHopGateway?.id || connection?.id;

        if (!destination || existingRouteIds.includes(nextHopId)) {
          return;
        }

        let nextHopType = destination?.nextHopGatewayType || connectionEntityType;
        if (nextHopType === EXPRESS_ROUTE_CONNECTION) {
          nextHopType = 'Express Route Connection';
        }
        if (nextHopType === VPN_GATEWAY) {
          nextHopType = 'VPN Gateway';
        }
        if (nextHopType === HUB_VIRTUAL_NETWORK_CONNECTION) {
          nextHopType = 'VNET Connection';
        }

        const route = {
          routeTable: hubRouteTable.name,
          routeTableId: hubRouteTable.id,
          nextHopType,
          nextHop: destination?.nextHopGateway?.name || connection.name,
          nextHopId,
          addressPrefix: destination?.destination,
          provisioningState: connection.properties.provisioningState
        };

        existingRouteIds.push(nextHopId);
        tableRoutes.push(route);
      });

      return tableRoutes;
    });

    return vhubRoutes.flat();
  }

  /**
   * will try to find property data in given resource
   * if property data has only id/uri, will try to find that property data in topology
   * if no such resource for property data in topology, will query azure api to pull data for that property resource
   */
  getEntityProperty(entity, property, entityType = null) {
    const propertyPath = property.includes('properties.') ? property : `properties.${property}`;

    const propertyDataValues = get(entity, propertyPath, null);

    // if property doesnt exist
    if (!propertyDataValues) {
      if (property.endsWith('s')) {
        let result;
        // example publicIPAddresses vs publicIPAddress
        if (property.endsWith('es')) {
          result = this.getEntityProperty(entity, property.slice(0, -2));

          // do this null check, to run removal of 's' only, example: RouteTables [has 'es' ending]-> RouteTable
          if (result) {
            return result;
          }
        }

        // remove ending s
        if (property.endsWith('s')) {
          return this.getEntityProperty(entity, property.slice(0, -1));
        }
      }
      return null;
    }

    const isPropertyDataArray = Array.isArray(propertyDataValues);

    const propertyDataArr = isPropertyDataArray ? propertyDataValues : [propertyDataValues];

    const returnValuesArr =
      propertyDataArr?.map((propertyData) => {
        const hasIdField = !!propertyData?.id ?? null;

        // we check > 1 to exclude id field
        const hasFieldData = Object.keys(propertyData).length > 1;

        if (hasFieldData) {
          return propertyData;
        }

        if (!hasIdField) {
          console.warn(`Property ${property} not found in entity ${entity.id}`);
          return null;
        }

        if (entityType && this.Entities[entityType] && this.Entities[entityType][propertyData.id]) {
          return this.Entities[entityType][propertyData.id];
        }

        // locate entity in topology
        return this.findEntityByIdUri(propertyData.id, property) ?? propertyData;
      }) ?? null;

    if (isPropertyDataArray) {
      return returnValuesArr;
    }

    return returnValuesArr.length > 0 ? returnValuesArr[0] : null;
  }

  // we want to declare same method here for consistency, similar to getEntityProperty
  getCustomProperties(entity) {
    return getCustomProperties(entity);
  }

  get Summary() {
    return this.initialTopology.Summary;
  }

  getSummaryEntities(entityType) {
    const { Summary } = this.initialTopology;
    return Object.values(Summary).reduce((carry, subscriptionSummary) => {
      const entities = subscriptionSummary?.[entityType] ?? {};
      Object.assign(carry, entities);
      return carry;
    }, {});
  }

  findEntityInSummary(entityType, id) {
    const { Summary } = this.initialTopology;

    for (const subscriptionId in Summary) {
      if (Object.hasOwn(Summary, subscriptionId)) {
        const subscriptionSummary = Summary[subscriptionId];

        if (subscriptionSummary?.[entityType]?.[id]) {
          return subscriptionSummary[entityType][id];
        }
      }
    }

    return null;
  }

  // will find and return whole entity object from azure topology, relatedEntities currently keep only id to main entity object
  getEntityRelatedEntities(entity, relatedEntityType) {
    const customProperties = this.getCustomProperties(entity);

    const relatedEntities = get(customProperties, `relatedEntities.${relatedEntityType}`, []);

    return relatedEntities.map(({ id }) => this.findEntityByIdUri(id, relatedEntityType));
  }

  isNodeHighlighted({ value, type }) {
    return this.highLightedNodes.some((node) => node.value === value && node.type === type);
  }

  resetHighlightedNodes() {
    this.highLightedNodes = [];
  }

  getEntityType(entity) {
    return getEntityType(entity);
  }

  // will local all neightboor hubs for current hub
  findNeighboorHubs(vHub) {
    const virtualWan = this.getEntityProperty(vHub, 'virtualWan', VIRTUAL_WAN);
    const virtualHubs = this.getEntityProperty(virtualWan, 'virtualHubs', VIRTUAL_HUB);
    return virtualHubs.filter((hub) => hub.id.toLocaleLowerCase() !== vHub.id.toLocaleLowerCase());
  }

  @action
  fetchConnectivityCheckerTopology(options) {
    return this.fetch(options);
  }
}

export default AzureCloudMapCollection;
