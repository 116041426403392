import { computed } from 'mobx';
import Model from 'core/model/Model';

export default class PerfMonitorServiceModel extends Model {
  // used for merging in health stats fetched test results
  @computed
  get testResultsKey() {
    const agent = this.get('agentModel');
    const test = this.get('serviceTestModel');

    if (agent && test) {
      return `${agent.id}-${test.id}`;
    }

    return null;
  }

  @computed
  get isMissingTest() {
    const agent = this.get('agentModel');

    return agent && agent.get('agent_status') === 'OK' && !this.get('serviceTestModel');
  }
}
