const COMPANY_STATUS_MAP = {
  V: 'Active',
  EXP: 'Expired',
  INI: 'Initializing',
  INACTIVE: 'Inactive',
  SUSP: 'Suspended'
};

const COMPANY_PLAN_MAP = {
  CUSTOMER: 'Customer',
  TRIAL: 'Trial',
  FREE: 'Free'
};

const COMPANY_PLATFORM_MAP = {
  pro: 'Pro',
  premier: 'Premier',
  standard: 'Standard',
  essentials: 'Essentials',
  classic: 'Classic',
  custom: 'Custom'
};

function getCompanyStatusLabel(company) {
  return COMPANY_STATUS_MAP[company.get('company_status')];
}

function getCompanyStatusOptions() {
  return Object.entries(COMPANY_STATUS_MAP).reduce((acc, [key, label]) => {
    if (key !== 'INI') {
      acc.push({ key, label });
    }
    return acc;
  });
}

function getCompanyPlanLabel(company) {
  return COMPANY_PLAN_MAP[company.get('company_plan_id')];
}

function getEditionText(edition) {
  return COMPANY_PLATFORM_MAP[edition] || 'Classic';
}

export {
  COMPANY_STATUS_MAP,
  COMPANY_PLAN_MAP,
  COMPANY_PLATFORM_MAP,
  getCompanyStatusLabel,
  getCompanyPlanLabel,
  getCompanyStatusOptions,
  getEditionText
};
